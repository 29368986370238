import { Typography, useTheme } from "@mui/material";

export const StatusCell = (props: {
  status: string;
  hasBeenReviewed: boolean;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  if(props.status === "") return <Typography />;

  let statusText = getEvaluationStatusText(props.status);
  if (props.hasBeenReviewed) {
    statusText += "*";
  }
  const statusTextColor =
    props.status === "READY_FOR_EDIT"
      ? "green"
      : props.status === "FINAL"
      ? "#F04438"
      : colors.grey[300];

  return (
    <Typography fontSize={13} fontWeight={700} color={statusTextColor}>
      <span title={statusText ?? "-"}>{statusText}</span>
    </Typography>
  );
};

export const getEvaluationStatusText = (value: string) => {
  switch (value) {
    case "FINAL":
      return "Final";
    case "READY_FOR_EDIT":
      return "Ready";
    case "WORK_IN_PROGRESS":
      return "WIP";
  }
  return "";
};
