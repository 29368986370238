import { getEvaluationGradeBackColor, getEvaluationGradeForeColor } from "../../../utils/evals";

export const GradeCell = (props: { grade?: number }) => {
    return (
        <span
        style={{
            fontWeight: 700,
            backgroundColor: getEvaluationGradeBackColor(
            props.grade ?? 0
            ),
            color: getEvaluationGradeForeColor(props.grade ?? 0),
            minWidth: 60,
            textAlign: "center",
        }}
        >
        {props.grade?.toFixed(1)}
        </span>
    );
};
