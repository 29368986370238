import { PlayerMeasureFragment } from "../../../graphql/generated/graphql";

export interface Measurable {
  value?: number | string;
  verified?: boolean;
}
export type WorkoutMeasure = {
  id: string;
  workoutTypeName: string;
  measureTypeName: string;
  value: number;
  isVerified?: boolean;
  workoutTypePriority: number;
};

export const mapToWorkoutMeasure = (
  playerWorkouts: PlayerMeasureFragment[] | undefined
) => {
  if (!playerWorkouts) return [];

  const workoutMap = playerWorkouts.map((item) => {
    return {
      id: item.workout.id,
      workoutTypeName: item.workout.workoutType.name,
      measureTypeName: item.measureType.shortName,
      value: item.value,
      isVerified: item.isVerified,
      workoutTypePriority: item.workout.workoutType.priority,
    };
  });
  return workoutMap;
};
