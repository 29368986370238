import { Box, SelectChangeEvent } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridColumnVisibilityModel,
  GridFilterModel,
} from "@mui/x-data-grid-premium";
import getCollegeEvalColumns, {
  AllCollegeEvalRow,
} from "./AllCollegeEvalColumns";
import { useState } from "react";
import AllCollegeEvalsGridToolbar from "./AllCollegeEvalsGridToolbar";
import {
  CollegeListEvaluationFragment,
  EvalTypeEnum,
  useGetCollegeEvaluationListQuery,
  useGetLatestWarForPlayersQuery,
} from "../../../../graphql/generated/graphql";
import { useDataGridStyles } from "../../../common/grids/useDataGridStyles";
import { useGetSchoolsForLookup } from "../../../schools/useGetSchoolsForLookup";
import { getPlayerMeasures } from "../../../common/measures/useGetPlayerMeasures";
import { getPlayerAge } from "../../../../utils/players";
import { getDefaultDraftYear, isPostDraft } from "../../../../utils/season";
import { formatDate } from "@sumer/shared/utils/dates";
import { getScoutDisplayName } from "../../../../utils/user";
import _ from "lodash";

export const AllCollegeEvalsGrid = () => {
  const [gridGroupBy, setGridGroupBy] = useState("none");
  const [gridDraftYear, setGridDraftYear] = useState(
    getDefaultDraftYear().toString()
  );

  // get scout and cross check evals
  const { data: scoutEvals, loading: scoutEvalsLoading } =
  useGetCollegeEvaluationListQuery({
    variables: { group: EvalTypeEnum.COLLEGE_SCOUT, eligibilityYear: Number(gridDraftYear) },
  });

  const { data: crossCheckEvals, loading: crossCheckEvalsLoading } =
  useGetCollegeEvaluationListQuery({
    variables: { group: EvalTypeEnum.COLLEGE_CROSS_CHECK, eligibilityYear: Number(gridDraftYear)  },
  });

  const evals = scoutEvals?.evals; 
  const crossChecks = crossCheckEvals?.evals;

  // get SAGE
  const playerIds = getDistinctPlayerIds(evals?.map((e) => e.player.id));
  
  const playerWar = useGetLatestWarForPlayersQuery({
    variables: {
      playerIds: playerIds ?? [],
      league: "ncaa",
    },
  });
  // get schools for lookup
  const schoolLookup = useGetSchoolsForLookup();
  
  // default filter to current draft year
  const [filter, setFilter] = useState<GridFilterModel>({
    items: [
      {
        columnField: "eligibilityYear",
        operatorValue: "equals",
        value: gridDraftYear,
      },
      {
        columnField: "draftYear",
        operatorValue: "equals",
        value: isPostDraft() ? gridDraftYear : null,
      },
    ],
  });
  const [rowGroupingModel, setRowGroupingModel] =
    useState<GridRowGroupingModel>(["none"]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      lastName: true,
      playerId: false,
      eligibilityYear: false,
      draftYear: false,
    });
  const handleDraftYearChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridDraftYear(value);

    // apply grid filter
    setFilter({
      items: [
        {
          columnField: "eligibilityYear",
          operatorValue: "equals",
          value: value,
        },
        {
          columnField: "draftYear",
          operatorValue: "equals",
          value: isPostDraft() ? value : null,
        },
      ],
    });
  };

  const handleGroupChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridGroupBy(value);
    setRowGroupingModel([value]);

    if (value != "none") {
      setColumnVisibilityModel({
        lastName: false,
        playerId: false,
        eligibilityYear: false,
        draftYear: false,
      });
    } else {
      setColumnVisibilityModel({
        lastName: true,
        playerId: false,
        eligibilityYear: false,
        draftYear: false,
      });
    }
  };

  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["lastName"],
      },
      sorting: {
        sortModel: [{ field: "grade", sort: "desc" }],
      },
      columns: {
        columnVisibilityModel: columnVisibilityModel,
      },
      filter: {
        filterModel: filter,
      },
    },
  });
  const [pageSize, setPageSize] = useState(10);

  // Columns
  const columns: GridColDef<AllCollegeEvalRow>[] = getCollegeEvalColumns();

  // Rows
  const rows: AllCollegeEvalRow[] = [];

  playerIds?.forEach((playerId) => {
    const mostRecent = getMostRecentScoutEval(evals, playerId);
    if(!mostRecent) return;

    const crossCheck = crossChecks?.find(
      (cc) => cc.player.id == playerId
    );
    const aa = playerWar.data?.playerAdvancedAnalytics.find(
      (w) => w.marvalPlayerId == mostRecent.player.id
    );
    const latestSchool = schoolLookup.schools?.find(
      (s) => s.id == mostRecent.player.latestSchoolId
    );
    const workoutMeasures = getPlayerMeasures(mostRecent.player.workouts);
    
    rows.push({
      rowId: mostRecent.id,
      position: mostRecent.position.shortName,
      positionSort: mostRecent.position.sort,
      fits: mostRecent.fits?.map((f) => f.fit.name),
      alerts: mostRecent.alerts?.map((f) => f.alert.abbreviation),
      playerId: mostRecent.player.id,
      firstName: mostRecent.player.firstName,
      lastName: mostRecent.player.lastName,
      headshotUrl: mostRecent.player.headshotUrl,
      schoolId: latestSchool?.id,
      schoolCode: latestSchool?.schoolCode,
      schoolName: latestSchool?.schoolName,
      grade: mostRecent.scoutGrade?.grade,
      crossCheckGrade: crossCheck?.scoutGrade?.grade,
      crossCheckScout: `${crossCheck?.user.firstName?.substring(
        0,
        1
      )}${crossCheck?.user.lastName?.substring(0, 1)}`,
      scout: getScoutDisplayName(mostRecent.user.firstName, mostRecent.user.lastName),
      jersey: mostRecent.player.jersey,
      lastUpdated: mostRecent.lastUpdated,
      eligibility: mostRecent.player.latestEligibility,
      redshirt: mostRecent.player.redshirt,
      eligibilityYear: mostRecent.player.latestEligibilityYear,
      xWar: aa?.xWar,
      gameCount: mostRecent.gameGrades.length,
      fallbackHeight: mostRecent.player.height,
      fallbackWeight: mostRecent.player.weight,
      fallbackSpeed: mostRecent.player.speed,
      height: workoutMeasures.height,
      weight: workoutMeasures.weight,
      speed: workoutMeasures.speed,
      status: mostRecent.status,
      hasBeenReviewed: mostRecent.finalizedOn != null,
      birthDate: mostRecent.player.latestBirthDate
        ? getPlayerAge(
            formatDate(new Date(mostRecent.player.latestBirthDate), "MM/DD/YYYY")
          )
        : undefined,
      draftYear: mostRecent.player.draftYear,
      //gradedTraitCount: missingTraits,
    });
  });

  return (
    <Box flex={1}>
      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
        }}
        columns={columns}
        sortingOrder={["desc", "asc"]}
        columnVisibilityModel={columnVisibilityModel}
        getRowId={(row) => row.rowId}
        rowHeight={40}
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 75]}
        rows={rows}
        loading={scoutEvalsLoading || crossCheckEvalsLoading}
        disableDensitySelector
        disableColumnSelector
        disableColumnResize
        disableColumnReorder
        disableColumnPinning
        disableSelectionOnClick
        components={{
          Toolbar: () => {
            return (
              <AllCollegeEvalsGridToolbar
                handleDraftYearChange={handleDraftYearChange}
                gridDraftYear={gridDraftYear}
                handleGroupChange={handleGroupChange}
                gridGroupBy={gridGroupBy}
                hideGroupBy={false}
              />
            );
          },
        }}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        apiRef={apiRef}
        initialState={initialState}
        groupingColDef={{ leafField: "lastName" }}
        filterModel={filter}
        onFilterModelChange={(newFilterModel) => {
          setFilter(newFilterModel);
        }}
        
      />
    </Box>
  );
};
const getDistinctPlayerIds = (playerIds: string[] | undefined) => {
  const seen = new Set<string>();
  const unique = playerIds?.filter((a) => {
    if (seen.has(a)) return false;
    seen.add(a);
    return true;
  });
  return unique;
}
const getMostRecentScoutEval = (
  evaluations: CollegeListEvaluationFragment[] | undefined,
  playerId: string) =>{
    const orderedEvals = _.orderBy(
      evaluations?.filter(
        (e) =>
          e.evalType.type === EvalTypeEnum.COLLEGE_SCOUT  &&
          e.player.id === playerId
      ),
      ["season", "lastUpdated"],
      ["desc", "desc"]
    );

    return orderedEvals.find((o) => o);
}

export default AllCollegeEvalsGrid;
