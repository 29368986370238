import { Stack, Typography, useTheme } from "@mui/material";
import AllProEvalsGrid from "./AllProEvalsGrid";
import { EvalsContainer } from "../college/AllCollegeEvals";

const AllProEvals = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <EvalsContainer>
      <Stack flex={1}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center">
            <Typography fontSize={28} fontWeight={700} color={colors.grey[800]}>
              Evaluations
            </Typography>
            <Typography
              fontSize={20}
              fontWeight={500}
              color={colors.grey[300]}
              ml={1}
            >
              {"(Pro)"}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          mt={3}
          flexDirection="row"
          alignItems="start"
          justifyContent="space-between"
        >
          <AllProEvalsGrid />
        </Stack>
      </Stack>
    </EvalsContainer>
  );
};

export default AllProEvals;
