import { GridColDef } from "@mui/x-data-grid-premium";
import {
  NameCell,
  ClubCell,
  FitsCell,
  MeasureCell,
  AlertsCell,
} from "../../common/tableCells";
import { CellValue } from "@sumer/shared/components/design/ColumnValues";
import { FreeAgencyCell } from "../../common/tableCells/FreeAgencyCell";
import { Measurable } from "../../common/measures/measures";
import {
  StatusCell,
  getEvaluationStatusText,
} from "../../common/tableCells/StatusCell";
import { getPlayerHeight } from "../../../utils/players";
import { GradeCell } from "../../common/tableCells/GradeCell";
import { getDefaultSeason } from "../../../utils/season";
import { Typography } from "@mui/material";

export interface ProEvalRow {
  rowId: string;
  type: string | undefined;
  season?: number | null;
  jersey?: string | null | undefined;
  position?: string;
  fits?: string | undefined;
  alerts?: string | undefined;
  playerId: string;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  clubId?: string | null | undefined;
  clubCode?: string;
  grade?: number;
  freeAgencyStatus: string | null | undefined;
  gameCount: string;
  fallbackHeight?: number | null | undefined;
  fallbackWeight?: number | null | undefined;
  fallbackSpeed?: number | null | undefined;
  height: Measurable | undefined;
  weight: Measurable | undefined;
  speed: Measurable | undefined;
  gradedTraitCount: string;
  lastUpdated: string;
  status: string;
  hasBeenReviewed: boolean;
  cutdownCutPercentage: number | undefined;
  priorSeasonGrade: number | undefined;
  cutdownPriority: boolean;
  cutdownBucket: string | undefined;
  playerStatus?: string;
  tradeCandidate?: boolean;
  shortList?: boolean;
}

export const getProEvalColumns = (): GridColDef<ProEvalRow>[] => {
  const columns: GridColDef<ProEvalRow>[] = [
    {field: "cutdownPriority"},
    {field: "shortList"},
    {field: "tradeCandidate"},
    {
      field: "status",
      headerName: "Stage",
      width: 75,
      headerClassName: "column-header",
      groupingValueGetter: (params) =>
        getEvaluationStatusText(params.row.status)
      ,
      renderCell: (params) => {
        return (
          <StatusCell
            status={params.row.status}
            hasBeenReviewed={params.row.hasBeenReviewed}
          ></StatusCell>
        );
      },
      valueGetter: (params) => getEvaluationStatusText(params.row.status),
    },
    { 
      field: "type", 
      headerName: "Type", 
      width: 70, 
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.type,
    },
    {
      field: "season",
      headerName: "Season",
      width: 75,
    },
    {
      field: "position",
      headerName: "Pos",
      width: 60,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.position,
      renderCell: (params) => {
        return <CellValue value={params.row.position} soft></CellValue>;
      },
      valueGetter: (params) => params.row.position,
    },
    {
      field: "fit",
      headerName: "FITs",
      width: 130,
      headerClassName: "column-header",
      groupingValueGetter: (params) => params.row.fits,
      renderCell: (params) => {
        return <FitsCell fits={params.row.fits}></FitsCell>;
      },
      valueGetter: (params) => params.row.fits,
    },
    {
      field: "lastName",
      headerName: "Player",
      width: 235,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.lastName,
      renderCell: (params) => {
        if (!params.row.lastName) return null;
        return (
          <NameCell
            id={params.row.playerId}
            jersey={params.row.jersey ?? "0"}
            first={params.row.firstName}
            last={params.row.lastName}
            headshot={params.row.headshotUrl}
            newTab
          />
        );
      },
      valueGetter: (params) =>
        params.row.lastName &&
        params.row.lastName + ", " + params.row.firstName,
    },
    {
      field: "club",
      headerName: "Club",
      width: 75,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return !params.row.clubCode 
          ? "Free Agent"
          : params.row.clubCode;
      },
      renderCell: (params) => {
        if (!params.row.clubCode) return "";
        return (
          <ClubCell
            id={params.row.clubId ?? ""}
            clubCode={params.row.clubCode}
            clubName={params.row.clubCode}
          />
        );
      },
      valueGetter: (params) => params.row.clubCode,
    },
    {
      field: "playerStatus",
      headerName: "Status",
      width: 85,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.row.playerStatus) return "";
        return <CellValue value={params.row.playerStatus} soft></CellValue>;
      },
    },
    {
      field: "freeAgencyStatus",
      headerName: "F.Agy",
      width: 85,
      headerClassName: "column-header",
      groupable: false,
      groupingValueGetter: (params) => {
        return params.row.freeAgencyStatus;
      },
      renderCell: (params) => {
        return <FreeAgencyCell status={params.row.freeAgencyStatus} />;
      },
      valueGetter: (params) => params.row.freeAgencyStatus,
    },
    {
      field: "height",
      headerName: "H",
      width: 50,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.height?.value}
            verified={params.row.height?.verified}
            fallbackValue={getPlayerHeight(params.row.fallbackHeight)}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.height?.value ?? getPlayerHeight(params.row.fallbackHeight),
    },
    {
      field: "weight",
      headerName: "W",
      width: 50,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.weight?.value}
            verified={params.row.weight?.verified}
            fallbackValue={params.row.fallbackWeight}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.weight?.value ?? params.row.fallbackWeight,
    },
    {
      field: "speed",
      headerName: "S",
      width: 50,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.speed?.value}
            verified={params.row.speed?.verified}
            fallbackValue={params.row.fallbackSpeed}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.speed?.value ?? params.row.fallbackSpeed,
    },
    {
      field: "priorSeasonGrade",
      headerName: "priorSeasonGrade",
      renderHeader: () => getDefaultSeason() -1,
      headerAlign: "center",
      minWidth: 60,
      type: "number",
      headerClassName: "column-header",
      groupingValueGetter: (params) => params.row.priorSeasonGrade,
      renderCell: (params) => {
        return  <GradeCell grade={params.row.priorSeasonGrade} />
      },
      valueGetter: (params) => params.row.priorSeasonGrade,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 90,
      headerClassName: "column-header",
      type: "number",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.grade,
      renderCell: (params) => {
        return <GradeCell grade={params.row.grade} />
      },
      valueGetter: (params) => params.row.grade,
    },
    {
      field: "alerts",
      headerName: "Alerts",
      width: 100,
      headerClassName: "column-header",
      sortable: false,
      groupable: false,
      groupingValueGetter: (params) => params.row.alerts,
      renderCell: (params) => {
        return <AlertsCell alerts={params.row.alerts}></AlertsCell>;
      },
      valueGetter: (params) => params.row.alerts,
    },
    {
      field: "gradedTraitCount",
      headerName: "Traits",
      width: 60,
      headerClassName: "column-header",
      groupable: false,
      sortable: false,
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return params.row.gradedTraitCount;
      },
      renderCell: (params) => {
        return params.row.gradedTraitCount;
      },
      valueGetter: (params) => params.row.gradedTraitCount,
    },
    {
      field: "gameCount",
      headerName: "Graded Games",
      minWidth: 70,
      headerClassName: "column-header",
      groupable: false,
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return params.row.gameCount;
      },
      renderCell: (params) => {
        return params.row.gameCount;
      },
      valueGetter: (params) => params.row.gameCount,
    },
    {
      field: "lastUpdated",
      headerName: "L.Updated",
      width: 95,
      type: "date",
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.lastUpdated,
      renderCell: (params) => {
        return params.row.lastUpdated;
      },
      valueGetter: (params) => {
        return params.row.lastUpdated;
      },
    },
    {
      field: "cutdownBucket",
      headerName: "Cut %",
      headerClassName: "column-header",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 95,
      renderCell: (params) => {
        if(!params.row.cutdownBucket) return null;
        return <CutdownBucketCell bucket={params.row.cutdownBucket} />
      },
      valueGetter: (params) => {
        return params.row.cutdownBucket;
      },
    },
  ];

  return columns;
};

const CutdownBucketCell = (props: {bucket: string}) => {
  const bgColor = props.bucket == "medium" ? "orange" : props.bucket == "high" ? "tomato" : "#ddd";
  const foreColor = props.bucket == "high" ? "whitesmoke" : "#333";
  return <Typography fontSize={".7rem"} style={{padding: 3, backgroundColor:  `${bgColor}`, width:70, textAlign:"center", color: `${foreColor}`}}>{props.bucket.toUpperCase()}</Typography>;
}

export default getProEvalColumns;
