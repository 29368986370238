import { FC } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { TransactionDateRange } from "./TransactionDateRange";

interface TransactionsGridToolbarProps {
  currentTxDateRange: TransactionDateRange;
  handleDateRangeChange: (e: SelectChangeEvent) => void;
}

export const TransactionsGridToolbar: FC<TransactionsGridToolbarProps> = ({ currentTxDateRange, handleDateRangeChange }) => {
  return (
    <Stack alignItems="center" flexDirection="row" justifyContent="space-between" mb={2}>
      <Stack flexDirection="row" justifyContent="space-between" flex={1}>
        <Box>
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{ marginRight: 10 }} />
          </GridToolbarContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" flex={1} mr={1}>
          <FormControl size="small">
            <Select size="small" value={currentTxDateRange} color="primary" sx={{ minWidth: 120 }} onChange={handleDateRangeChange}>
              <MenuItem key={TransactionDateRange.Last48Hours} value={TransactionDateRange.Last48Hours}>
                <DateRangeSelect label="Last 48 Hours" />
              </MenuItem>

              <MenuItem key={TransactionDateRange.LastWeek} value={TransactionDateRange.LastWeek}>
                <DateRangeSelect label="Last Week" />
              </MenuItem>

              <MenuItem key={TransactionDateRange.LastMonth} value={TransactionDateRange.LastMonth}>
                <DateRangeSelect label="Last 30 Days" />
              </MenuItem>

              <MenuItem key={TransactionDateRange.LastYear} value={TransactionDateRange.LastYear}>
                <DateRangeSelect label="Last Year" />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Stack>
  );
};

interface DateRangeSelectProps {
  label: string;
}

const DateRangeSelect: FC<DateRangeSelectProps> = ({ label }) => {
  return (
        <Stack flexDirection="row" alignItems="center">
          <Typography fontSize={".9rem"} mr={0.75}>
            Date Range:
          </Typography>
          <Typography fontSize={".9rem"} fontWeight={700}>
            {label}
          </Typography>
        </Stack>
      );
};