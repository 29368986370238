import _ from "lodash";
import { getWingSpanDifferential } from "../../../utils/players";
import { Measurable, WorkoutMeasure, mapToWorkoutMeasure } from "./measures";
import { PlayerMeasureFragment } from "../../../graphql/generated/graphql";

export const getPlayerMeasures = (
  measures: PlayerMeasureFragment[] | undefined,
  isPro?: boolean | undefined
) => {
  return getMeasures(mapToWorkoutMeasure(measures), isPro);
};

export const getMeasures = (measures: WorkoutMeasure[] | undefined, isPro?: boolean | undefined) => {
  if (measures == undefined) return { value: undefined, verified: false };

  // order should be by workout type priority
  const orderedWorkouts = _.orderBy(measures, [(e) => e.workoutTypePriority]);

  const uniqueWorkouts = orderedWorkouts
    .map((w) => w.id)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  let height: Measurable | undefined;
  let weight: Measurable | undefined;
  let speed: Measurable | undefined;
  let arm: Measurable | undefined;
  let hand: Measurable | undefined;
  let wing: Measurable | undefined;
  let test1: Measurable | undefined;
  let test2: Measurable | undefined;

  uniqueWorkouts?.forEach((workoutId) => {
    const workouts = measures.filter((wpm) => wpm.id == workoutId);

    const workoutType = workouts ? workouts[0].workoutTypeName : "Unknown";

    // HEIGHT
    const heightMeasure = workouts?.find((wpm) => wpm.measureTypeName == "H");
    const heightVal = {
      value: heightMeasure?.value,
      verified: heightMeasure?.isVerified,
    };

    // WEIGHT
    const weightMeasure = workouts?.find((wpm) => wpm.measureTypeName == "W");
    const weightVal = {
      value: weightMeasure?.value,
      verified: weightMeasure?.isVerified,
    };

    // SPEED
    const speedMeasure = workouts?.find((wpm) => wpm.measureTypeName == "40");
    const speedVal = {
      value: speedMeasure?.value.toFixed(2),
      verified: speedMeasure?.isVerified,
    };

    // ARM
    const armMeasure = workouts?.find((wpm) => wpm.measureTypeName == "ARM");
    const armVal = {
      value: armMeasure?.value,
      verified: armMeasure?.isVerified,
    };

    // HAND
    const handMeasure = workouts?.find((wpm) => wpm.measureTypeName == "HAND");
    const handVal = {
      value: handMeasure?.value,
      verified: handMeasure?.isVerified,
    };

    // WING
    const wingMeasure = workouts?.find((wpm) => wpm.measureTypeName == "WING");
    const wingVal = {
      value: wingMeasure?.value,
      verified: wingMeasure?.isVerified,
    };

    const testCorrect1 = workouts?.find(
      (wpm) => wpm.measureTypeName == "TestCorrect1"
    );
    const testAttempt1 = workouts?.find(
      (wpm) => wpm.measureTypeName == "TestAttempt1"
    );
    const testCorrect2 = workouts?.find(
      (wpm) => wpm.measureTypeName == "TestCorrect2"
    );
    const testAttempt2 = workouts?.find(
      (wpm) => wpm.measureTypeName == "TestAttempt2"
    );

    // Order of precedence: Combine, Pro Day, AllStar, SpringCombine, Scout, SumerSports
    if (!height) {
      if (workoutType == "Combine" && heightVal.value) {
        height = heightVal;
      } else if (workoutType == "ProDay" && heightVal.value) {
        height = heightVal;
      } else if (workoutType == "AllStar" && heightVal.value) {
        height = heightVal;
      } else if (workoutType == "SpringCombine" && heightVal.value) {
        height = heightVal;
      } else if (workoutType == "Scout" && heightVal.value) {
        height = heightVal;
      } else if (workoutType == "SumerSports" && heightVal.value) {
        height = heightVal;
      }
    }

    // Order of precedence: Combine, Pro Day, AllStar, SumerSports
    if (!weight) {
      if (workoutType == "Combine" && weightVal.value) {
        weight = weightVal;
      } else if (workoutType == "ProDay" && weightVal.value) {
        weight = weightVal;
      } else if (workoutType == "AllStar" && weightVal.value) {
        weight = weightVal;
      } else if (workoutType == "SpringCombine" && weightVal.value) {
        weight = weightVal;
      } else if (workoutType == "Scout" && weightVal.value) {
        weight = weightVal;
      } else if (workoutType == "SumerSports" && weightVal.value) {
        weight = weightVal;
      }
    }

    // Order of precedence: Combine, Pro Day, AllStar, SumerSports
    if (!speed) {
      if (workoutType == "Combine" && speedVal.value) {
        speed = speedVal;
      } else if (workoutType == "ProDay" && speedVal.value) {
        speed = speedVal;
      } else if (workoutType == "AllStar" && speedVal.value) {
        speed = speedVal;
      } else if (workoutType == "SpringCombine" && speedVal.value) {
        speed = speedVal;
      } else if (workoutType == "Scout" && speedVal.value) {
        speed = speedVal;
      } else if (workoutType == "SumerSports" && speedVal.value) {
        speed = speedVal;
      }
    }

    // Order of precedence: Combine, Pro Day, AllStar, SumerSports
    if (!arm) {
      if (workoutType == "Combine" && armVal.value) {
        arm = armVal;
      } else if (workoutType == "ProDay" && armVal.value) {
        arm = armVal;
      } else if (workoutType == "AllStar" && armVal.value) {
        arm = armVal;
      } else if (workoutType == "SpringCombine" && armVal.value) {
        arm = armVal;
      } else if (workoutType == "Scout" && armVal.value) {
        arm = armVal;
      } else if (workoutType == "SumerSports" && armVal.value) {
        arm = armVal;
      }
    }

    // Order of precedence: Combine, Pro Day, AllStar, SumerSports
    if (!hand) {
      if (workoutType == "Combine" && handVal.value) {
        hand = handVal;
      } else if (workoutType == "ProDay" && handVal.value) {
        hand = handVal;
      } else if (workoutType == "AllStar" && handVal.value) {
        hand = handVal;
      } else if (workoutType == "SpringCombine" && handVal.value) {
        hand = handVal;
      } else if (workoutType == "Scout" && handVal.value) {
        hand = handVal;
      } else if (workoutType == "SumerSports" && handVal.value) {
        hand = handVal;
      }
    }

    // Order of precedence: Combine, Pro Day, AllStar, SumerSports
    if (!wing) {
      if (workoutType == "Combine" && wingVal.value) {
        wing = wingVal;
      } else if (workoutType == "ProDay" && wingVal.value) {
        wing = wingVal;
      } else if (workoutType == "AllStar" && wingVal.value) {
        wing = wingVal;
      } else if (workoutType == "SpringCombine" && wingVal.value) {
        wing = wingVal;
      } else if (workoutType == "Scout" && wingVal.value) {
        wing = wingVal;
      } else if (workoutType == "SumerSports" && wingVal.value) {
        wing = wingVal;
      }
    }

    if (testCorrect1) {
      test1 = {
        value: `${testCorrect1.value}/${testAttempt1?.value}/1`,
        verified: false,
      };
    }
    if (testCorrect2) {
      test2 = {
        value: `${testCorrect2.value}/${testAttempt2?.value}/2`,
        verified: false,
      };
    }
    
    // for pro players, take scout workouts for weight if exists
    if(isPro) {
      if (workoutType == "Scout" && weightVal.value) {
        weight = weightVal;
      }
    }
  });

  const wsd = {
    value: getWingSpanDifferential(wing?.value, height?.value),
    verified: false,
  };

  return { height, weight, speed, arm, hand, wing, wsd, test1, test2 };
};
