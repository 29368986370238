import { Box, SelectChangeEvent } from "@mui/material";
import {
  DataGridPremium,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridColumnVisibilityModel,
  GridColDef,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { BigBoardEvalRow } from "./BigBoardEvalColumns";
import { useSaveBigBoardPlayersMutation } from "../../../../graphql/generated/graphql";
import BigBoardEvalsGridToolbar from "./BigBoardEvalToolbar";
import { useDataGridStyles } from "../../../common/grids/useDataGridStyles";

interface BigBoardEvalsGridProps {
  rows: BigBoardEvalRow[];
  columns: GridColDef<BigBoardEvalRow>[];
  loading: boolean;
}

export const BigBoardEvalsGrid = ({
  rows,
  columns,
  loading,
}: BigBoardEvalsGridProps) => {
  const [gridGroupBy, setGridGroupBy] = useState("none");
  const [rowGroupingModel, setRowGroupingModel] =
    useState<GridRowGroupingModel>(["none"]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      lastName: true,
      positionSort: false,
    });
  const [pageSize, setPageSize] = useState<number>(50);
  const [newRows, setNewRows] = useState<BigBoardEvalRow[]>([]);

  const handleGroupChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridGroupBy(value);
    setRowGroupingModel([value]);

    if (value != "none") {
      setColumnVisibilityModel({
        lastName: false,
        positionSort: false,
      });
    } else {
      setColumnVisibilityModel({
        lastName: true,
        positionSort: false,
      });
    }
  };

  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
  });

  const [saveBigBoardPlayersMutation, saveBigBoardPlayersMutationState] =
    useSaveBigBoardPlayersMutation();

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const isOutsideOfGrade =
      params.row.grade !== rows[params.targetIndex].grade;
    if (isOutsideOfGrade) {
      setNewRows((prev) => [...prev]);
      return;
    }

    const rowsClone = [...rows];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);

    const sameGradeRows = rowsClone.filter((r) => r.grade == params.row?.grade);
    sameGradeRows.forEach((r, index) => (r.rank = index + 1));

    const bigBoardRequest = sameGradeRows.map((r) => ({
      playerId: r.playerId,
      rank: r.rank as number,
    }));

    setNewRows(rowsClone);
    saveBigBoardPlayersMutation({
      variables: {
        request: {
          players: bigBoardRequest,
        },
      },
    });
  };

  useEffect(() => {
    setNewRows(rows);
  }, [rows]);

  return (
    <Box flex={1}>
      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
        }}
        columnVisibilityModel={columnVisibilityModel}
        getRowId={(row) => row.rowId}
        autoHeight
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[50, 100, 300, 500]}
        rows={newRows}
        rowHeight={50}
        rowCount={rows.length}
        loading={loading || saveBigBoardPlayersMutationState.loading}
        disableDensitySelector
        disableColumnSelector
        disableColumnResize
        disableColumnReorder
        disableColumnPinning
        disableSelectionOnClick
        components={{
          Toolbar: () => {
            return (
              <BigBoardEvalsGridToolbar
                handleGroupChange={handleGroupChange}
                gridGroupBy={gridGroupBy}
                displayRecommendationButton={false}
                hideGroupBy={true}
              />
            );
          },
        }}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        apiRef={apiRef}
        initialState={initialState}
        groupingColDef={{ leafField: "lastName" }}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      />
    </Box>
  );
};
export default BigBoardEvalsGrid;
