import moment from "moment";

export const CURRENT_DRAFT_YEAR = 2025; //TODO: update each May-June

export function formatDate(date: Date, format: string) {
  return moment(date).format(format);
}

export function GetCurrentYear() {
  return moment().year();
}
