import { Stack, Typography, useTheme } from "@mui/material";
import FreeAgencyGrid from "./FreeAgencyGrid";
import { EvalsContainer } from "../all/college/AllCollegeEvals";
import {
  PlayerSearchSortCriteriaEnum,
  useGetFreeAgencyPlayersQuery,
} from "../../../graphql/generated/graphql";

const FreeAgency = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const { data, loading } = useGetFreeAgencyPlayersQuery({
    variables: {
      sortCriteria: PlayerSearchSortCriteriaEnum.SUMER_GENERAL_POSITION,
    },
  });
  // const evals = scoutEvals?.evals.filter((e) => e.isPrimary);

  return (
    <EvalsContainer>
      <Stack flex={1}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center">
            <Typography fontSize={28} fontWeight={700} color={colors.grey[800]}>
              Upcoming Free Agents
            </Typography>
          </Stack>
        </Stack>
        <Stack
          mt={5}
          flexDirection="row"
          alignItems="start"
          justifyContent="space-between"
        >
          <FreeAgencyGrid players={data?.players} loading={loading} />
        </Stack>
      </Stack>
    </EvalsContainer>
  );
};

export default FreeAgency;
