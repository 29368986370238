import { formatDate } from "@sumer/shared/utils/dates";
import {
  EvalStatusEnum,
  EvalTypeEnum,
  ScoutTypeEnum,
  useGetAssignedProPlayersQuery,
  useGetNflCutdownPredictionsQuery,
  useGetTraitGroupsQuery,
  useGetUserQuery,
} from "../../../graphql/generated/graphql";
import { getMissingTraitCount, getScoutAssignmentTypeDisplay } from "../../../utils/evals";
import { getDefaultSeason } from "../../../utils/season";
import { getPlayerMeasures } from "../../common/measures/useGetPlayerMeasures";
import { ProEvalRow } from "./ProEvalColumns";
import _ from "lodash";
import { getPlayerStatus, PlayerTagEnum } from "../../../utils/players";
import { getDistinctAlerts, getDistinctFits } from "../homeUtils";

export const useGetProEvaluationsForUser = () => {
  const { data, loading: userLoading } = useGetUserQuery();
  const user = data?.user;
  
  const defaultSeason = getDefaultSeason();

  // get all players tagged as TBG filtered by scout assignment
  const { data: playersToBeGraded, loading: playersToBeGradedLoading } = useGetAssignedProPlayersQuery({
    variables: {
      userId: user?.id ?? "no-id"
    },
    skip: user === undefined
  });
  const cutdownProspectPlayerIds = playersToBeGraded?.scoutingAssignments.map((p) => p.playerId != null && p.playerId) as string[];

    // get cutdown cut percentage
  const {data: cutdownPredictions, loading: cutdownPredictionsLoading } = useGetNflCutdownPredictionsQuery({variables: {
    playerIds: cutdownProspectPlayerIds ?? []
  }});

  const { data: traitGroups, loading: traitGroupsLoading } = useGetTraitGroupsQuery();

  const rows: ProEvalRow[] = [];

  // map to grid object
  playersToBeGraded?.scoutingAssignments?.forEach((a) => {

    // could be a position or club assignment or college crosscheck (not currently implemented)
    if(!a.player || a.player.draftYear == null) return null;

    const player = a.player;

    const workoutMeasures = getPlayerMeasures(player.workouts);

    // get moset recent eval for user of scout assignment type
    const evalType = a.scoutType == ScoutTypeEnum.PRO ? EvalTypeEnum.PRO_SCOUT : EvalTypeEnum.PRO_CROSS_CHECK;
    const scoutEvals = _.orderBy(player.evaluations.filter((e) => e.user.id === user?.id && e.season == defaultSeason
          && e.evalType.type == evalType), [s => s.lastUpdated], ["desc"]);

    const scoutEval = scoutEvals.find(s => s);

    // get prior season grade
    const priorSeasonGrade = player.evaluations.find((e) => e.season == defaultSeason - 1 
      && e.evalType.type == EvalTypeEnum.PRO_SCOUT
      && e.status == EvalStatusEnum.FINAL)?.scoutGrade?.grade;

    const missingTraits = scoutEval ? getMissingTraitCount(
      scoutEval?.traitGrades,
      traitGroups?.traitGroups,
      scoutEval?.position.id
    ) : "";

    const cutdown = cutdownPredictions?.nflCutdownPredictions.find((ncp) => ncp.marvalPlayerId == player.id);
    const cutdownPriority = player.tags.find((t) => t.tag.name == PlayerTagEnum.CutdownPriority);
    const shortList = player.tags.find((t) => t.tag.name == PlayerTagEnum.ShortList);
    const tradeCandidate = player.tags.find((t) => t.tag.name == PlayerTagEnum.TradeCandidate);

    // get evals by scout
    rows.push({
      rowId: player.id,
      type: getScoutAssignmentTypeDisplay(a.scoutType),
      jersey: player.jersey,
      season: defaultSeason,
      position: player.sumerGeneralPosition?.shortName,
      fits: getDistinctFits(player, true) as string,
      alerts: getDistinctAlerts(player, true) as string | undefined,
      playerId: player.id,
      firstName: player.firstName,
      lastName: player.lastName,
      headshotUrl: player.headshotUrl,
      clubId: player.club?.id,
      clubCode: player.club?.clubCode,
      grade: scoutEval?.scoutGrade?.grade,
      freeAgencyStatus: player.freeAgencyStatus,
      gameCount: scoutEval?.gameGrades.length.toString() ?? "",
      fallbackHeight: player.height,
      fallbackWeight: player.weight,
      fallbackSpeed: player.speed,
      height: workoutMeasures.height,
      weight: workoutMeasures.weight,
      speed: workoutMeasures.speed,
      lastUpdated: scoutEval?.lastUpdated ? formatDate(new Date(scoutEval?.lastUpdated), "MM/DD/YYYY") : "",
      gradedTraitCount: missingTraits,
      status: scoutEval?.status ?? "",
      hasBeenReviewed: scoutEval?.finalizedOn != null,
      priorSeasonGrade:priorSeasonGrade,
      cutdownCutPercentage: cutdown?.cutProb ?? 0,
      cutdownBucket: cutdown?.cutdownBucket,
      cutdownPriority: cutdownPriority != null, 
      shortList: shortList != null,
      tradeCandidate: tradeCandidate != null,
      playerStatus: getPlayerStatus(player.status),
  });
});

  return {
    rows,
    loading: playersToBeGradedLoading || traitGroupsLoading || userLoading || cutdownPredictionsLoading,
    count: rows?.length,
  };
};

export default useGetProEvaluationsForUser;
