import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { useExportEvals } from "../useExportEvals";

interface BigBoardEvalsGridToolbarProps {
  handleGroupChange: (event: SelectChangeEvent) => void;

  gridGroupBy: string;

  displayRecommendationButton?: boolean;
  hideGroupBy: boolean;
}

const BigBoardEvalsGridToolbar = ({
  handleGroupChange,
  gridGroupBy,
  displayRecommendationButton = true,
  hideGroupBy,
}: BigBoardEvalsGridToolbarProps) => {
  const { exportSumerRecommended } = useExportEvals("College");

  return (
    <Stack
      flexDirection="row"
      mb={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" justifyContent="space-between" flex={1}>
        <Box>
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{ marginRight: 10 }} />
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{ disableToolbarButton: true }}
              excelOptions={{ allColumns: true }}
            />
            {displayRecommendationButton && (
              <Button
                variant="text"
                onClick={exportSumerRecommended}
                style={{ fontSize: 13 }}
                title="Download Sumer Recommended by Club"
              >
                <StarIcon
                  color="secondary"
                  fontSize="small"
                  style={{ marginRight: 1 }}
                />
                Hot List
              </Button>
            )}
          </GridToolbarContainer>
        </Box>
        {!hideGroupBy && (
          <FormControl size="small">
            <Select
              size="small"
              value={gridGroupBy}
              sx={{ width: 250 }}
              color="primary"
              onChange={handleGroupChange}
            >
              <MenuItem key={1} value={"none"}>
                <GroupBy label="None" />
              </MenuItem>
              <MenuItem key={2} value={"position"}>
                <GroupBy label="Position" />
              </MenuItem>
              <MenuItem key={3} value={"school"}>
                <GroupBy label="School" />
              </MenuItem>
              <MenuItem key={4} value={"scout"}>
                <GroupBy label="Scout" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};

const GroupBy = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography mr={0.75}>Group By: </Typography>
      <Typography fontWeight={500}>{props.label}</Typography>
    </Stack>
  );
};

export default BigBoardEvalsGridToolbar;
