import { useCallback, useMemo, useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from "@mui/x-data-grid-premium";
import moment from "moment";
import { TransactionRow, getTransactionsGridColumns } from "./TransactionsGridColumns";
import { TransactionDateRange } from "./TransactionDateRange";
import { TransactionsGridToolbar } from "./TransactionsGridToolbar";
import { useDataGridStyles } from "../../common/grids/useDataGridStyles";
import { GetTransactionsByDateRangeQuery, SortEnumType, TransactionFragment, useGetTransactionsByDateRangeQuery } from "../../../graphql/generated/graphql";
import { getPlayerAge } from "../../../utils/players";
import { formatDate } from "@sumer/shared/utils/dates";

interface DateRange {
  fromDate: string;
  toDate: string;
}

interface TransactionRowState {
  page: number;
  pageSize: number;
  loading: boolean;
}

const getDateRange = (range: TransactionDateRange): DateRange => {
  const currentDate = moment();
  let fromDate = "";
  const toDate = currentDate.startOf("day").format();

  switch (range) {
    case TransactionDateRange.Last48Hours:
      fromDate = currentDate.subtract(48, 'hours').startOf("day").format();
      break;
    case TransactionDateRange.LastWeek:
      fromDate = currentDate.subtract(7, 'days').startOf("day").format();
      break;
    case TransactionDateRange.LastMonth:
      fromDate = currentDate.subtract(1, 'months').startOf("day").format();
      break;
    case TransactionDateRange.LastYear:
      fromDate = currentDate.subtract(1, 'years').startOf("day").format();
      break;
  }

  return {
    fromDate,
    toDate
  };
};

function mapQuery(result: GetTransactionsByDateRangeQuery | undefined) {
  if (result?.transactionsByDateRange?.edges) {
    return result.transactionsByDateRange.edges.map((e) => e.node);
  }
  return [];
}

// map players to rows
function MapToGridRow(transactions: TransactionFragment[])  {

const rows: TransactionRow[] = [];

transactions?.forEach(t => {

  rows.push({
    rowId: t.id,
    season: t.season,
    transactionDate: moment(t.transactionDate).format("MM-DD-YYYY"),
    playerId: t.player?.id,
    firstName: t.player?.firstName,
    lastName: t.player?.lastName,
    jersey: t.player?.jersey,
    position: t.player.sumerGeneralPosition?.shortName,
    headshotUrl: t.player?.headshotUrl,
    initialStatus: t.initialStatus.transStatusShortDesc,
    resultStatus: t.resultStatus.transStatusShortDesc,
    description: t.transactionDescription.transactionDesc,
    toClubId: t.resultClub?.id,
    toClub: t.resultClub?.clubCode,
    fromClubId: t.startClub?.id,
    fromClub: t.startClub?.clubCode,
    potentialClubId: t.potentialClub?.id,
    potentialClub: t.potentialClub?.clubCode,
    age: t.player?.birthDate
    ? getPlayerAge(
        formatDate(new Date(t.player?.birthDate), "MM/DD/YYYY")
      )
    : undefined,
    draftYear: t.player?.draftYear,
    accruedSeasons: t.player?.accruedSeasons ?? 0
    });
  });
  
  return rows;
}

export const TransactionsGrid = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  
  //TODO: handle sorting
  const [sortOptions, setSortOptions] = useState({});
  const [searchFilter, setSearchFilter] = useState("");

  const [cursor, setCursor] = useState<
    | { after: string | undefined; first: number }
    | { before: string; last: number }
    | undefined
  >({ first: 10, after: undefined });

  const [transactionRowState, setTransactionRowState] = useState<TransactionRowState>({
    page: 0,
    pageSize: 10,
    loading: false,
  });

// retrieve transactions with default query
const [transactionsDateRange, setTransactionsDateRange] = useState<TransactionDateRange>(TransactionDateRange.Last48Hours);
const { data: transactions, loading: transactionsAreLoading } =
  useGetTransactionsByDateRangeQuery({
    variables: {
      ...getDateRange(transactionsDateRange),
      ...(cursor ? cursor : {}),
      name: searchFilter,
      order: sortOptions,
      //order: [{ ["transactionDate"]: SortEnumType.DESC }, {["lastUpdated"]: SortEnumType.ASC}]
    },
  });

  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      sorting: {
        sortModel: [{ field: "transactionDate", sort: "desc"}, { field: "lastUpdated", sort: "asc"}]
      },
    },
  });

  const gridReset = useCallback(() => {
    setCurrentPageIndex(0);
    setTransactionRowState((prev) => ({ ...prev, page: 0}));
    setCursor({ first: transactionRowState.pageSize, after: undefined });
    setSearchFilter("");
  },[transactionRowState.pageSize]);

  const handleDateRangeChange = useCallback((e: SelectChangeEvent) => {
    const { value } = e.target;
    setTransactionsDateRange(value as TransactionDateRange)

    // need to reset with range change
    gridReset();
  },[gridReset]);

  // handle page size change
  const handlePageSizeChange = useCallback((pageSize: number) => {
    setTransactionRowState((prev) => ({ ...prev, pageSize }));
    setCursor({ first: pageSize, after: undefined });
    setCurrentPageIndex(0);
    setTransactionRowState((prev) => ({ ...prev, page: 0 }));
  },[]);

  // handle page change
  const handlePageChange = useCallback((page: number) => {
    if (page > currentPageIndex) {
      //  fetch next page
      if (transactions?.transactionsByDateRange?.pageInfo.hasNextPage) {
        setCursor({
          after: transactions?.transactionsByDateRange?.pageInfo.endCursor || "error",
          first: transactionRowState.pageSize,
        });
      }
    } else {
      //  fetch previous page
      if (transactions?.transactionsByDateRange?.pageInfo.hasPreviousPage) {
        setCursor({
          before: transactions?.transactionsByDateRange?.pageInfo.startCursor || "error",
          last: transactionRowState.pageSize,
        });
      }
    }

    setCurrentPageIndex(page);
    setTransactionRowState((prev) => ({ ...prev, page }));
  },[currentPageIndex,transactions,transactionRowState]);

  const handleFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    if (newFilterModel.quickFilterValues) {
      setSearchFilter(newFilterModel.quickFilterValues.find((e) => e) ?? "");
    }
    setFilterModel(newFilterModel);
  }, []);

  //handle sorting
  const updateSortOptions = useCallback(
    (sortField: string, sortOrder: SortEnumType) => {
      gridReset();

      switch (sortField) {
        case "name":
          setSortOptions({ player: { lastName: sortOrder} });
          break;
        case "position":
          setSortOptions({ player: { sumerGeneralPosition: { shortName: sortOrder} } });
          break;
        case "resultStatus":
          setSortOptions({ resultStatus: { transStatusShortDesc: sortOrder} });
          break;
        case "description":
          setSortOptions({ transactionDescription: { transactionDesc: sortOrder } });
          break;
        case "transactionDate":
          setSortOptions({ transactionDate: sortOrder });
          break;
        case "fromClub":
          setSortOptions({ startClub: { clubCode: sortOrder } });
          break;
        case "toClub":
          setSortOptions({ resultClub: { clubCode: sortOrder } });
          break;
        case "potentialClub":
          setSortOptions({ potentialClub: { clubCode: sortOrder } });
          break;
      }
    },
    [gridReset]
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const sortItem = sortModel[0];
      if (sortItem) {
        const sortOrder =
          sortItem.sort === "asc" ? SortEnumType.ASC : SortEnumType.DESC;
        updateSortOptions(sortItem.field, sortOrder);
      }
    },
    [updateSortOptions]
  );

  const columns: GridColDef<TransactionRow>[] = getTransactionsGridColumns();
  const rows = useMemo(() => MapToGridRow(mapQuery(transactions)), [transactions]);
  const rowCount = transactions?.transactionsByDateRange?.totalCount ?? 0;

  return (
    <Box sx={{ height: "100%" }}>
      <DataGridPremium
        components={{
          Toolbar: () => (
            <TransactionsGridToolbar
              currentTxDateRange={transactionsDateRange}
              handleDateRangeChange={handleDateRangeChange}
              />
          )
        }}
        sx={{
          ...useDataGridStyles(),
          minHeight: 585 // fixed height so not jumpy
        }}
        getRowId={(row) => row.rowId}
        columns={columns}
        pagination
        paginationMode="server"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        {...transactionRowState}
        rows={rows}
        rowCount={rowCount}
        loading={transactionsAreLoading}
        rowHeight={40}
        rowsPerPageOptions={[10, 25, 50]}
        disableColumnSelector
        disableDensitySelector
        disableColumnResize
        disableColumnReorder
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        apiRef={apiRef}
        initialState={initialState}
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={(model) => handleFilterChange(model)}
        // handle sorting
        sortingMode="server"
        sortingOrder={["asc", "desc"]}
        onSortModelChange={handleSortModelChange}
       />
    </Box>
  );
};