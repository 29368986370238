import { Stack, Typography, useTheme } from "@mui/material";

import {
  EvalTypeEnum,
  useGetBigBoardEvalSummariesQuery,
  useGetBigBoardPlayersQuery,
} from "../../../../graphql/generated/graphql";
import { EvalsContainer } from "../college/AllCollegeEvals";
import BigBoardEvalsGridPro from "./BigBoardEvalsGridPro";
import getBigBoardEvalProColumns, {
  BigBoardEvalProRow,
} from "./BigBoardEvalColumnsPro";
import { GridColDef } from "@mui/x-data-grid-premium";
import { assignDefaultRank, sortBy } from "../../../../utils/evals";
import { getPlayerJerseyDisplay } from "../../../../utils/players";
import { getPlayerMeasures } from "../../../common/measures/useGetPlayerMeasures";

const AllProEvals = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const { data } = useGetBigBoardPlayersQuery();

  //get pro evals
  const { data: scoutEvals, loading: scoutEvalsLoading } =
    useGetBigBoardEvalSummariesQuery({
      variables: { group: EvalTypeEnum.PRO_SCOUT },
    });

  const evals = scoutEvals?.evals.filter((e) => e.isPrimary);

  // Columns
  const columns: GridColDef<BigBoardEvalProRow>[] = getBigBoardEvalProColumns();

  // Rows
  const rows: BigBoardEvalProRow[] = [];

  evals?.forEach((e) => {
    const rank = data?.bigBoardPlayers.find(
      (bbp) => bbp.playerId == e.player.id
    )?.rank;
    const reorderDisplayText = `${
      e.position.shortName
    } ${getPlayerJerseyDisplay(e.player.jersey)} ${e.player.firstName[0]}. ${
      e.player.lastName
    }`;
    const workoutMeasures = getPlayerMeasures(e.player.workouts, e.player.draftYear != null);

    rows.push({
      rowId: e.id,
      position: e.position.shortName,
      playerId: e.player.id,
      firstName: e.player.firstName,
      lastName: e.player.lastName,
      headshotUrl: e.player.headshotUrl,
      grade: e.scoutGrade?.grade,
      rank: rank,
      jersey: e.player.jersey,
      division: e.player.club?.division,
      clubCode: e.player.club?.clubCode,
      clubId: e.player.club?.id,
      freeAgencyStatus: e.player.freeAgencyStatus,
      fallbackHeight: e.player.height,
      fallbackWeight: e.player.weight,
      fallbackSpeed: e.player.speed,
      height: workoutMeasures.height,
      weight: workoutMeasures.weight,
      speed: workoutMeasures.speed,
      arm: workoutMeasures.arm,
      hand: workoutMeasures.hand,
      wing: workoutMeasures.wing,
      wsd: workoutMeasures.wsd,
      __reorder__: reorderDisplayText,
    });
  });

  sortBy(rows, "positionSort", "descending");
  sortBy(rows, "rank");
  sortBy(rows, "grade", "descending");

  assignDefaultRank(rows);

  return (
    <EvalsContainer>
      <Stack flex={1}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center">
            <Typography fontSize={28} fontWeight={700} color={colors.grey[800]}>
              Evaluations by Grade and Rank
            </Typography>
            <Typography
              fontSize={20}
              fontWeight={500}
              color={colors.grey[300]}
              ml={1}
            >
              {"(Pro)"}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          mt={3}
          flexDirection="row"
          alignItems="start"
          justifyContent="space-between"
        >
          <BigBoardEvalsGridPro
            rows={rows}
            columns={columns}
            loading={scoutEvalsLoading}
          />
        </Stack>
      </Stack>
    </EvalsContainer>
  );
};

export default AllProEvals;
