import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { useExportEvals } from "../useExportEvals";
import { getDefaultDraftYear } from "../../../../utils/season";
//import useExportEvalMatrix from "../useExportEvalMatrix";

interface AllCollegeEvalsGridToolbarProps {
  handleGroupChange: (event: SelectChangeEvent) => void;
  handleDraftYearChange: (event: SelectChangeEvent) => void;
  gridGroupBy: string;
  gridDraftYear: string;
  displayRecommendationButton?: boolean;
  hideGroupBy: boolean;
}

const AllCollegeEvalsGridToolbar = ({
  handleGroupChange,
  handleDraftYearChange,
  gridGroupBy,
  gridDraftYear,
  displayRecommendationButton = true,
  hideGroupBy,
}: AllCollegeEvalsGridToolbarProps) => {
  const { exportSumerRecommended } = useExportEvals("College");
  // TODO
  //const { exportEvalMatrix } = useExportEvalMatrix("College", gridDraftYear);
  const defaultDraftYear = getDefaultDraftYear();
  return (
    <Stack
      flexDirection="row"
      mb={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" justifyContent="space-between" flex={1}>
        <Box>
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{ marginRight: 10 }} />
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{ disableToolbarButton: true }}
              excelOptions={{ allColumns: true }}
            />
            {displayRecommendationButton && (
              <Button
                variant="text"
                onClick={exportSumerRecommended}
                style={{ fontSize: 13 }}
                title="Download Sumer Recommended by Club"
              >
                <StarIcon
                  color="secondary"
                  fontSize="small"
                  style={{ marginRight: 1 }}
                />
                Hot List
              </Button>
            )}
          </GridToolbarContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1} mr={1}>
          <FormControl size="small">
            <Select
              size="small"
              value={gridDraftYear}
              color="primary"
              sx={{ minWidth: 125 }}
              onChange={handleDraftYearChange}
            >
              {getSeasons(defaultDraftYear - 1,defaultDraftYear + 3).map(
                (season) => (
                  <MenuItem key={season} value={season.toString()}>
                    <SeasonSelect label={season.toString()} />
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        {!hideGroupBy && (
          <FormControl size="small">
            <Select
              size="small"
              value={gridGroupBy}
              sx={{ width: 200 }}
              color="primary"
              onChange={handleGroupChange}
            >
              <MenuItem key={1} value={"none"}>
                <GroupBy label="None" />
              </MenuItem>
              <MenuItem key={2} value={"position"}>
                <GroupBy label="Position" />
              </MenuItem>
              <MenuItem key={3} value={"school"}>
                <GroupBy label="School" />
              </MenuItem>
              <MenuItem key={4} value={"scout"}>
                <GroupBy label="Scout" />
              </MenuItem>
              <MenuItem key={5} value={"status"}>
                <GroupBy label="Status" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};

const getSeasons = (firstYear: number, lastYear: number) => {
  const seasons = [];
  while (firstYear <= lastYear) {
    seasons.push(firstYear);
    firstYear++;
  }
  return seasons;
};

const SeasonSelect = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography fontSize={".9rem"} mr={0.75}>Draft Year: </Typography>
      <Typography fontSize={".9rem"} fontWeight={700}>{props.label}</Typography>
    </Stack>
  );
};
const GroupBy = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography fontSize={".9rem"}  mr={0.75}>Group By: </Typography>
      <Typography fontSize={".9rem"}  fontWeight={700}>{props.label}</Typography>
    </Stack>
  );
};

export default AllCollegeEvalsGridToolbar;
