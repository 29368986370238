import { GridColDef } from "@mui/x-data-grid-premium";
import { Avatar, Chip } from "@mui/material";
import { formatDate } from "@sumer/shared/utils/dates";
import {
  ClubCell,
  FitsCell,
  NameCell,
  AlertsCell,
} from "../../../common/tableCells";
import { CellValue } from "@sumer/shared/components/design/ColumnValues";
import { FreeAgencyCell } from "../../../common/tableCells/FreeAgencyCell";
import { StatusCell, getEvaluationStatusText } from "../../../common/tableCells/StatusCell";
import { GradeCell } from "../../../common/tableCells/GradeCell";

export interface AllProEvalRow {
  rowId: string;
  season: number;
  position?: string;
  playerId: string;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  clubId?: string | null | undefined;
  clubCode?: string;
  clubHome?: string;
  grade?: number;
  division?: string;
  fits?: string[];
  alerts?: string[];
  scout?: string;
  jersey?: string | null | undefined;
  freeAgencyStatus: string | null | undefined;
  lastUpdated: string;
  gameCount: number;
  status: string;
  hasBeenReviewed: boolean;
  playerStatus?: string;
}

const getProEvalColumns = (): GridColDef<AllProEvalRow>[] => {
  const columns: GridColDef<AllProEvalRow>[] = [
    {
      field:"season",
      headerName: "Season",
      headerClassName: "column-header",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "division",
      headerName: "Division",
      headerClassName: "column-header",
      type: "string",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.division,
      renderCell: (params) => {
        if (!params.row.division) return null;
        return <CellValue value={params.row.division}></CellValue>;
      },
      valueGetter: (params) => params.row.division,
    },
    {
      field: "position",
      headerName: "Pos",
      width: 60,
      headerClassName: "column-header",
      groupable: true,
      groupingValueGetter: (params) => params.row.position,
      renderCell: (params) => {
        if (!params.row.position) return null;
        return <CellValue value={params.row.position}></CellValue>;
      },
      valueGetter: (params) => params.row.position,
    },
    {
      field: "fit",
      headerName: "FITs",
      width: 125,
      headerClassName: "column-header",
      sortable: false,
      groupable: false,
      groupingValueGetter: (params) => params.row.fits?.join(", "),
      renderCell: (params) => {
        return <FitsCell fits={params.row.fits?.join(", ")}></FitsCell>;
      },
      valueGetter: (params) => params.row.fits?.join(", "),
    },
    {
      field: "name",
      headerName: "Player",
      width: 220,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.lastName,
      renderCell: (params) => {
        if (!params.row.lastName) return null;
        return (
          <NameCell
            id={params.row.playerId}
            jersey={params.row.jersey ?? "0"}
            first={params.row.firstName}
            last={params.row.lastName}
            headshot={params.row.headshotUrl}
            anchor
            newTab
          />
        );
      },
      valueGetter: (params) =>
        params.row.lastName &&
        params.row.lastName + ", " + params.row.firstName,
    },
    {
      field: "club",
      headerName: "Club",
      width: 80,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return !params.row.clubCode || !params.row.clubHome
          ? "Street"
          : params.row.clubCode;
      },
      renderCell: (params) => {
        if (!params.row.clubCode || !params.row.clubHome) return "";
        return (
          <ClubCell
            id={params.row.clubId ?? ""}
            clubCode={params.row.clubCode}
            clubName={params.row.clubHome}
            displayLogo={false}
          />
        );
      },
      valueGetter: (params) => params.row.clubCode,
    },
    {
      field: "playerStatus",
      headerName: "Status",
      width: 85,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.row.playerStatus) return "";
        return <CellValue value={params.row.playerStatus} soft></CellValue>;
      },
    },
    {
      field: "freeAgencyStatus",
      headerName: "F.Agy",
      width: 85,
      headerClassName: "column-header",
      groupingValueGetter: (params) => {
        return params.row.freeAgencyStatus;
      },
      renderCell: (params) => {
        return <FreeAgencyCell status={params.row.freeAgencyStatus} />;
      },
      valueGetter: (params) => params.row.freeAgencyStatus,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 95,
      headerClassName: "column-header",
      disableColumnMenu: true,
      type: "number",
      groupingValueGetter: (params) => params.row.grade,
      renderCell: (params) => {
        return  <GradeCell grade={params.row.grade} />
      },
      valueGetter: (params) => params.row.grade?.toFixed(1),
    },
    {
      field: "alerts",
      headerName: "Alerts",
      width: 115,
      headerClassName: "column-header",
      sortable: false,
      groupable: false,
      groupingValueGetter: (params) => params.row.alerts?.join(", "),
      renderCell: (params) => {
        return <AlertsCell alerts={params.row.alerts?.join(", ")}></AlertsCell>;
      },
      valueGetter: (params) => params.row.alerts?.join(", "),
    },
    {
      field: "gameCount",
      headerName: "Games",
      width: 75,
      headerClassName: "column-header",
      sortable: false,
      groupable: false,
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.gameCount,
      renderCell: (params) => {
        return params.row.gameCount;
      },
      valueGetter: (params) => params.row.gameCount,
    },
    {
      field: "status",
      headerName: "Stage",
      width: 75,
      headerClassName: "column-header",
      groupingValueGetter: (params) =>
        getEvaluationStatusText(params.row.status),
      renderCell: (params) => {
        return (
          <StatusCell
            status={params.row.status}
            hasBeenReviewed={params.row.hasBeenReviewed}
          ></StatusCell>
        );
      },
      valueGetter: (params) => getEvaluationStatusText(params.row.status),
    },
    {
      field: "scout",
      headerName: "Scout",
      width: 135,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.scout,
      renderCell: (params) => {
        if (!params.row.scout) return null;
        return (
          <Chip
            avatar={<Avatar></Avatar>}
            label={params.row.scout}
            size="small"
          />
        );
      },
      valueGetter: (params) => params.row.scout,
    },
    {
      field: "lastUpdated",
      headerName: "L.Updated",
      flex: 1,
      minWidth: 95,
      headerClassName: "column-header",
      disableColumnMenu: true,
      type: "date",
      groupingValueGetter: (params) => params.row.lastUpdated,
      renderCell: (params) => {
        if (!params.row.lastUpdated || params.row.lastUpdated == "") return "";
        return formatDate(new Date(params.row.lastUpdated), "MM/DD/YYYY");
      },
      valueGetter: (params) => new Date(params.row.lastUpdated),
    },
    {
      field: "playerId",
      headerName: "PlayerId",
      headerClassName: "column-header",
      disableColumnMenu: true,
      valueGetter: (params) => params.row.playerId,
    },
  ];

  return columns;
};

export default getProEvalColumns;
