import { Typography } from "@mui/material";
import { getDefaultDraftYear } from "../../utils/season";

export function PlayerEligibility({
  eligYear,
  elig,
  redshirt,
}: {
  eligYear: number | null | undefined;
  elig: string | null | undefined;
  redshirt: boolean;
}) {
  const getFontColor = () => {
    if (redshirt) return "red";
    if (elig != "SR" && elig != "G") return "green";
    return "black";
  };
  if (eligYear && elig && eligYear >= getDefaultDraftYear()) {
    return (
      <Typography
        style={{
          fontSize: ".8rem",
          paddingRight: 3,
          paddingLeft: 3,
          textAlign: "center",
          border: "solid 1px #eee",
          width: 28,
          backgroundColor: "whitesmoke",
          color: `${getFontColor()}`,
        }}
      >
        {elig.toUpperCase()}
      </Typography>
    );
  }
  return null;
}
