export const shortDollars = (
  num?: number | null,
  places?: number | null
): string => {
  if (num === null || num === undefined) return "N/A";
  if (places === null || places === undefined) places = 1;

  const absNum = Math.abs(num);
  let dollars = "";
  if (absNum >= 1000000) {
    dollars = (num / 1000000).toFixed(places) + "M";
  } else if (absNum >= 1000) {
    dollars = (num / 1000).toFixed() + "K";
  } else {
    dollars = num.toString();
  }
  return "$" + dollars;
};

export const shortDollarRange = (
  min?: number | null,
  max?: number | null,
  places?: number | null
): string => {
  if (min === null || min === undefined) return "--";
  if (max === null || max === undefined) return "--";
  if (places === null || places === undefined) places = 1;
  const minNum = ShortDollarNumber(min, places, false);
  const maxNum = ShortDollarNumber(max, places);

  return `$${minNum}-${maxNum}`;
};

const ShortDollarNumber = (
  num: number,
  places?: number | null,
  includeSuffix?: boolean | null
) => {
  if (places === null || places === undefined) places = 1;
  if (includeSuffix === null || includeSuffix === undefined)
    includeSuffix = true;

  const absNum = Math.abs(num);
  let dollars = "";
  if (absNum >= 1000000) {
    places = (num / 1000000) % 1 !== 0 ? 1 : 0; // if the decimal is 0, don't show it
    dollars = includeSuffix
      ? (num / 1000000).toFixed(places) + "M"
      : (num / 1000000).toFixed(places);
  } else if (absNum >= 1000) {
    dollars = includeSuffix
      ? (num / 1000).toFixed() + "K"
      : (num / 1000).toFixed();
  } else {
    dollars = num.toString();
  }
  return dollars;
};
export const toCurrency = (num?: number | null): string => {
  return (
    "$" +
    Math.round(num ?? 0)
      ?.toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};
