import { GridColDef } from "@mui/x-data-grid-premium";
import {
  NameCell,
  ClubCell,
  FitsCell,
  AlertsCell,
  MeasureCell,
} from "../../common/tableCells";
import { CellValue } from "@sumer/shared/components/design/ColumnValues";
import { FreeAgencyCell } from "../../common/tableCells/FreeAgencyCell";
import { getPlayerHeight } from "../../../utils/players";
import { Measurable } from "../../common/measures/measures";
import { GradeCell } from "../../common/tableCells/GradeCell";

export interface FreeAgentPlayerRow {
  rowId: string;
  sumerAtlas?: string;
  sumerGeneral: string;
  playerId: string;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  clubId?: string | null | undefined;
  clubCode?: string;
  grade?: number;
  fits?: string[];
  alerts?: string[];
  jersey?: string | null | undefined;
  freeAgencyStatus: string | null | undefined;
  draftYear: number | null | undefined;
  age: number | undefined;
  positionSort: number;
  fallbackHeight?: number | null | undefined;
  fallbackWeight?: number | null | undefined;
  fallbackSpeed?: number | null | undefined;
  height: Measurable | undefined;
  weight: Measurable | undefined;
  speed: Measurable | undefined;
  hand: Measurable | undefined;
  arm: Measurable | undefined;
  wing: Measurable | undefined;
  wsd: Measurable | undefined;
  sage: number | undefined;
}

const getFreeAgentPlayerColumns = (): GridColDef<FreeAgentPlayerRow>[] => {
  const columns: GridColDef<FreeAgentPlayerRow>[] = [
    {
      field: "sumerGeneral",
      headerName: "Pos",
      width: 65,
      headerClassName: "column-header",
      groupable: true,
      sortable: false,
      groupingValueGetter: (params) => params.row.sumerGeneral,
      renderCell: (params) => {
        if (!params.row.sumerGeneral) return null;
        return <CellValue value={params.row.sumerGeneral}></CellValue>;
      },
      valueGetter: (params) => params.row.sumerGeneral,
    },
    {
      field: "fit",
      headerName: "FITs",
      width: 125,
      headerClassName: "column-header",
      sortable: false,
      groupable: false,
      groupingValueGetter: (params) => params.row.fits?.join(", "),
      renderCell: (params) => {
        return <FitsCell fits={params.row.fits?.join(", ")}></FitsCell>;
      },
      valueGetter: (params) => params.row.fits?.join(", "),
    },
    {
      field: "name",
      headerName: "Player",
      minWidth: 245,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.lastName,
      renderCell: (params) => {
        if (!params.row.lastName) return null;
        return (
          <NameCell
            id={params.row.playerId}
            jersey={params.row.jersey ?? "0"}
            first={params.row.firstName}
            last={params.row.lastName}
            headshot={params.row.headshotUrl}
          />
        );
      },
      valueGetter: (params) =>
        params.row.lastName &&
        params.row.lastName + ", " + params.row.firstName,
    },
    {
      field: "age",
      headerName: "Age",
      minWidth: 70,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "column-header",
      valueGetter: (params) => params.row.age,
      renderCell: (params) => {
        return <CellValue value={params.row.age} soft />;
      },
    },
    {
      field: "club",
      headerName: "Club",
      width: 70,
      headerClassName: "column-header",
      sortable: false,
      groupingValueGetter: (params) => {
        return !params.row.clubCode || !params.row.clubCode
          ? "Free Agent"
          : params.row.clubCode;
      },
      renderCell: (params) => {
        if (!params.row.clubCode || !params.row.clubCode) return "";
        return (
          <ClubCell
            id={params.row.clubId ?? ""}
            clubCode={params.row.clubCode}
            clubName={params.row.clubCode}
            displayLogo={false}
          />
        );
      },
      valueGetter: (params) => params.row.clubCode,
    },
    {
      field: "draftYear",
      headerName: "Draft",
      width: 70,
      headerClassName: "column-header",
      valueGetter: (params) => params.row.draftYear,
      renderCell: (params) => {
        return (
          <CellValue value={params.row.draftYear?.toString() ?? ""} soft />
        );
      },
    },
    {
      field: "freeAgencyStatus",
      headerName: "F.Agy",
      width: 70,
      headerClassName: "column-header",
      groupingValueGetter: (params) => {
        return params.row.freeAgencyStatus;
      },
      renderCell: (params) => {
        return <FreeAgencyCell status={params.row.freeAgencyStatus} />;
      },
      valueGetter: (params) => params.row.freeAgencyStatus,
    },
    {
      field: "sage",
      headerName: "SAGE",
      width: 70,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return params.row.sage;
      },
      renderCell: (params) => {
        return params.row.sage?.toFixed(4);
      },
      valueGetter: (params) => params.row.sage,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 75,
      headerClassName: "column-header",
      disableColumnMenu: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      groupingValueGetter: (params) => params.row.grade,
      renderCell: (params) => {
        return  <GradeCell grade={params.row.grade} />
      },
      valueGetter: (params) => params.row.grade?.toFixed(1),
    },
    {
      field: "alerts",
      headerName: "Alerts",
      minWidth: 115,
      headerClassName: "column-header",
      sortable: false,
      groupable: false,
      groupingValueGetter: (params) => params.row.alerts?.join(", "),
      renderCell: (params) => {
        return <AlertsCell alerts={params.row.alerts?.join(", ")}></AlertsCell>;
      },
      valueGetter: (params) => params.row.alerts?.join(", "),
    },
    {
      field: "height",
      headerName: "H",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.height?.value}
            verified={params.row.height?.verified}
            fallbackValue={getPlayerHeight(params.row.fallbackHeight)}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.height?.value ?? getPlayerHeight(params.row.fallbackHeight),
    },
    {
      field: "weight",
      headerName: "W",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.weight?.value}
            verified={params.row.weight?.verified}
            fallbackValue={params.row.fallbackWeight}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.weight?.value ?? params.row.fallbackWeight,
    },
    {
      field: "speed",
      headerName: "S",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.speed?.value}
            verified={params.row.speed?.verified}
            fallbackValue={params.row.fallbackSpeed}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.speed?.value ?? params.row.fallbackSpeed,
    },
    {
      field: "hand",
      headerName: "Hand",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.hand?.value}
            verified={params.row.hand?.verified}
            soft
          />
        );
      },
      valueGetter: (params) => params.row.hand?.value,
    },
    {
      field: "arm",
      headerName: "Arm",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.arm?.value}
            verified={params.row.arm?.verified}
            soft
          />
        );
      },
      valueGetter: (params) => params.row.arm?.value,
    },
    {
      field: "wing",
      headerName: "Wing",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.wing?.value}
            verified={params.row.wing?.verified}
            soft
          />
        );
      },
      valueGetter: (params) => params.row.wing?.value,
    },
    {
      field: "wsd",
      headerName: "WSD",
      flex: 1,
      minWidth: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.wsd?.value}
            includeVerification={false}
            soft
          />
        );
      },
      valueGetter: (params) => params.row.wsd?.value,
    },
    // {
    //   field: "clubFits",
    //   headerName: "Club Fits",
    //   width: 150,
    //   headerClassName: "column-header",
    //   sortable: false,
    //   groupable: false,
    //   groupingValueGetter: (params) => params.row.clubFits?.join(", "),
    //   renderCell: (params) => {
    //     return <FitsCell fits={params.row.clubFits?.join(", ")}></FitsCell>;
    //   },
    //   valueGetter: (params) => params.row.clubFits?.join(", "),
    // },
    // {
    //   field: "lastUpdated",
    //   headerName: "L.Updated",
    //   flex: 1,
    //   minWidth: 95,
    //   headerClassName: "column-header",
    //   disableColumnMenu: true,
    //   groupingValueGetter: (params) => params.row.lastUpdated,
    //   renderCell: (params) => {
    //     if (!params.row.lastUpdated || params.row.lastUpdated == "") return "";
    //     return formatDate(new Date(params.row.lastUpdated), "MM/DD/YYYY");
    //   },
    //   valueGetter: (params) =>
    //     formatDate(new Date(params.row.lastUpdated), "MM/DD/YYYY"),
    // },
    {
      field: "positionSort",
      headerName: "Sort",
      headerClassName: "column-header",
      type: "number",
      disableExport: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.positionSort,
    },
    {
      field: "playerId",
      headerName: "PlayerId",
      headerClassName: "column-header",
      disableColumnMenu: true,
      valueGetter: (params) => params.row.playerId,
    },
  ];

  return columns;
};

export default getFreeAgentPlayerColumns;
