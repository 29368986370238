import { Stack, Typography, styled, useTheme } from "@mui/material";
import AllCollegeEvalsGrid from "./AllCollegeEvalsGrid";

const AllCollegeEvals = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <EvalsContainer>
      <Stack flex={1}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center">
            <Typography fontSize={28} fontWeight={700} color={colors.grey[800]}>
              Evaluations
            </Typography>
            <Typography
              fontSize={20}
              fontWeight={500}
              color={colors.grey[300]}
              ml={1}
            >
              {"(College)"}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          mt={3}
          flexDirection="row"
          alignItems="start"
          justifyContent="space-between"
        >
          <AllCollegeEvalsGrid />
        </Stack>
      </Stack>
    </EvalsContainer>
  );
};
export const EvalsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "40px 40px 10px 40px",
  marginTop: "20px",
  marginBottom: "40px",
  borderRadius: 4,
  backgroundColor: theme.palette.common.white,
  boxShadow: `8px 8px 10px 0px ${theme.palette.grey[300]}`,
}));

export default AllCollegeEvals;
