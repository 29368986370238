import { Skeleton, Stack, Typography, styled, useTheme } from "@mui/material";

export const MyEvalsSkeleton = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <>
      <EvalsLoadingContainer>
        <Stack flex={1}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack flexDirection="row" alignItems="center">
              <Typography
                fontSize={28}
                fontWeight={700}
                color={colors.grey[800]}
              >
                My Evals
              </Typography>
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={3}
            mb={3}
          >
            <Stack spacing={1} flex={1}>
              <EvalSkeleton />
              <EvalSkeleton />
              <EvalSkeleton />
              <EvalSkeleton />
              <EvalSkeleton />
            </Stack>
          </Stack>
        </Stack>
      </EvalsLoadingContainer>
    </>
  );
};

const EvalSkeleton = () => {
  return (
    <Skeleton variant="rounded" height={25} sx={{ alignSelf: "stretch" }} />
  );
};

const EvalsLoadingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "40px 40px 10px 40px",
  marginTop: "20px",
  marginBottom: "40px",
  borderRadius: 4,
  backgroundColor: theme.palette.common.white,
  boxShadow: `8px 8px 10px 0px ${theme.palette.grey[300]}`,
}));

export default MyEvalsSkeleton;
