import { CircularProgress, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { ClubCell, NameCell } from "../../common/tableCells";
import { useGetPlayerTransactionContractByIdQuery } from "../../../graphql/generated/graphql";
import { shortDollars } from "@sumer/shared/utils/dollarUtils";
import { getContractYears } from "../../../utils/cap";
import { CheckCircle } from "@mui/icons-material";

export interface TransactionRow {
  rowId: string;
  season: number;
  transactionDate: string;
  playerId: string;
  firstName: string;
  lastName: string;
  initialStatus: string;
  resultStatus: string;
  accruedSeasons: number;
  position?: string;
  description?: string;
  toClubId?: string;
  toClub?: string;
  fromClubId?: string;
  fromClub?: string;
  potentialClubId?: string;
  potentialClub?: string;
  jersey?: string | null;
  headshotUrl?: string | null;
  age?: number | undefined;
  draftYear?: number | null;
}

export const getTransactionsGridColumns = (): GridColDef<TransactionRow>[] => {
  return [
    {
      field: "transactionDate",
      headerName: "Date",
      headerClassName: "column-header",
      width: 95,
      type: "date",
      disableColumnMenu: true,
      groupingValueGetter: params => params.row.transactionDate,
      renderCell: params => <CellValue value={params.row.transactionDate} soft />,
      valueGetter: params => new Date(params.row.transactionDate),
    },
    {
      field: "position",
      headerName: "Pos",
      width: 50,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.position,
      renderCell: (params) => {
        if (!params.row.position) return null;
        return <CellValue value={params.row.position}></CellValue>;
      },
      valueGetter: (params) => params.row.position,
    },
    {
      field: "name",
      headerName: "Player",
      headerClassName: "column-header",
      minWidth: 250,
      disableColumnMenu: true,
      groupingValueGetter: params => params.row.lastName,
      renderCell: (params) => {
        if (!params.row.lastName) return null;
        return (
          <NameCell
            id={params.row.playerId}
            jersey={params.row.jersey ?? "0"}
            first={params.row.firstName}
            last={params.row.lastName}
            headshot={params.row.headshotUrl}
            anchor
            newTab
          />
        );
      },
      valueGetter: params => params.row.lastName,
    },
    {
      field: "age",
      headerName: "Age",
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.age,
      renderCell: (params) => {
        if (!params.row.age) return null;
        return (
          <CellValue value={params.row.age?.toString()} soft />
        );
      },
    },
    {
      field: "draftYear",
      headerName: "Draft",
      width: 65,
      headerClassName: "column-header",
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => params.row.draftYear,
      renderCell: (params) => {
        if (!params.row.draftYear) return null;
        return (
          <CellValue value={params.row.draftYear?.toString()} soft />
        );
      },
    },
    {
      field: "accruedSeasons",
      headerName: "AS",
      headerClassName: "column-header",
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => params.row.accruedSeasons,
      renderCell: (params) => {
        return (
          <CellValue value={params.row.accruedSeasons.toString()} soft />
        );
      },
    },
    {
      field: "resultStatus",
      headerName: "Status",
      minWidth: 150,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.resultStatus,
      renderCell: (params) => {
        return <CellValue value={params.row.resultStatus} soft />;
      },
      valueGetter: (params) => params.row.resultStatus,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 280,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.description,
      renderCell: (params) => {
        if (!params.row.description) return null;
        return <CellValue value={params.row.description} />;
      },
      valueGetter: (params) => params.row.description,
    },
    {
      field: "fromClub",
      headerName: "From Club",
      minWidth: 90,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.fromClub,
      renderCell: (params) => {
        if (!params.row.fromClub) return null;
        return <ClubCell clubCode={params.row.fromClub} clubName={params.row.fromClub} id={params.row.fromClubId ?? ""} />;
      },
      valueGetter: (params) => params.row.fromClub,
    },
    {
      field: "toClub",
      headerName: "To Club",
      minWidth: 90,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.toClub,
      renderCell: (params) => {
        if (!params.row.toClub) return null;
        return <ClubCell clubName={params.row.toClub} clubCode={params.row.toClub} id={params.row.toClubId ?? ""} />;
      },
      valueGetter: (params) => params.row.toClub,
    },
    {
      field: "potentialClub",
      headerName: "Pot. Club",
      minWidth: 90,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.potentialClub,
      renderCell: (params) => {
        if (!params.row.potentialClub) return null;
        return <ClubCell clubName={params.row.potentialClub ?? ""} clubCode={params.row.potentialClub ?? ""} id={params.row.potentialClubId ?? ""} />;
      },
      valueGetter: (params) => params.row.potentialClub,
    },
    {
      field: "totalGuaranteed",
      headerName: "Total G$",
      headerClassName: "column-header",
      width: 75,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return <GuaranteedCell playerId={params.row.playerId} status={params.row.resultStatus} />
      }
    },
    {
      field: "years",
      headerName: "Years",
      headerClassName: "column-header",
      width: 60,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return <ContractYearsCell playerId={params.row.playerId} status={params.row.resultStatus} />
      }
    },
    {
      field: "evaluated",
      headerName: "",
      headerClassName: "column-header",
      minWidth: 40,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return <EvaluatedCell playerId={params.row.playerId} />
      }
    }
  ];
};

const CellValue = (props: { value: string | number; soft?: boolean }) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Typography
      fontSize={13}
      fontWeight={props.soft ? 400 : 600}
      color={colors.grey[600]}
      title={props.value.toString()}
    >
      {props.value}
    </Typography>
  );
};

const GuaranteedCell = (props: { playerId: string | null; status: string; }) => {
    const theme = useTheme();
    const colors = theme.palette;

    const {data, loading} = useGetPlayerTransactionContractByIdQuery({
      variables: {
        id: props.playerId ?? "no-id"
      },
      skip: !props.playerId && props.status == "Free Agent",
    });

    if (props.status == "Free Agent") 
      return <Typography fontSize={13} color={colors.grey[700]}>N/A</Typography>;
    
    if (loading)
      return <CircularProgress size={20} />;

    const contract = data?.playerContractsById.find((c) => c);
    return (
      <Typography fontSize={13} color={colors.grey[700]}>
        {shortDollars(contract?.total.guaranteed)}
      </Typography>
    );
};

const EvaluatedCell = (props: { playerId: string | null }) => {
  const {data} = useGetPlayerTransactionContractByIdQuery({
    variables: {
      id: props.playerId ?? "no-id"
    },
    skip: !props.playerId,
  });
 
  const contract = data?.playerContractsById.find((c) => c);
  return (
    <Typography fontSize={14} color={"green"}>
      {contract?.sumer.evaluated && <CheckCircle fontSize="small" />}
    </Typography>
  );
};

const ContractYearsCell = (props: { playerId: string | null; status: string; }) => {
  const theme = useTheme();
  const colors = theme.palette;

  const {data, loading} = useGetPlayerTransactionContractByIdQuery({
    variables: {
      id: props.playerId ?? "no-id"
    },
    skip: !props.playerId && props.status == "Free Agent",
  });

  if (props.status == "Free Agent") 
    return <Typography fontSize={13} color={colors.grey[700]}>N/A</Typography>;
  
  if (loading)
    return <CircularProgress color="info" size={20} />;

  const contract = data?.playerContractsById.find((c) => c);
  const years = contract ? 
      getContractYears(contract?.firstContractYear, contract?.effectiveLastContractYear, contract?.signingType)
      : "N/A";
  return (
    <Typography fontSize={13} color={colors.grey[700]}>
      {years}
    </Typography>
  );
};