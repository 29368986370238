import { Box, SelectChangeEvent } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import CollegeEvalsToolbar from "./CollegeEvalsToolbar";
import getCollegeEvalColumns, { CollegeEvalRow } from "./CollegeEvalColumns";
import { useDataGridStyles } from "../../common/grids/useDataGridStyles";
import useGetCollegeEvaluationsForUser from "./useGetCollegeEvaluationsForUser";
import { useState } from "react";

export const CollegeEvalsGrid = () => {
  const [gridGroupBy, setGridGroupBy] = useState("none");
  const [pageSize, setPageSize] = useState(10);
  const [rowGroupingModel, setRowGroupingModel] =
    useState<GridRowGroupingModel>(["none"]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      lastName: true,
      eligibilityYear: false,
    });

  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["lastName"],
      },
      sorting: {
        sortModel: [{ field: "grade", sort: "desc" }],
      },
    },
  });

  const handleGroupChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridGroupBy(value);
    setRowGroupingModel([value]);

    if (value != "none") {
      setColumnVisibilityModel({
        lastName: false,
        positionSort: false,
        playerId: false,
        eligibilityYear: false,
      });
    } else {
      setColumnVisibilityModel({
        lastName: true,
        positionSort: false,
        playerId: false,
        eligibilityYear: false,
      });
    }
  };

  // Columns
  const columns: GridColDef<CollegeEvalRow>[] = getCollegeEvalColumns();

  // Rows
  const { rows, loading } = useGetCollegeEvaluationsForUser();

  return (
    <Box flex={1}>
      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
        }}
        columns={columns}
        sortingOrder={["desc", "asc"]}
        columnVisibilityModel={columnVisibilityModel}
        getRowId={(row) => row.rowId}
        rowHeight={40}
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 75]}
        rows={rows}
        loading={loading}
        disableDensitySelector
        disableColumnSelector
        disableColumnResize
        disableColumnReorder
        disableColumnPinning
        disableSelectionOnClick
        components={{
          Toolbar: () => {
            return (
              <CollegeEvalsToolbar
                handleGroupChange={handleGroupChange}
                gridGroupBy={gridGroupBy}
                hideGroupBy={false}
              />
            );
          },
        }}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        apiRef={apiRef}
        initialState={initialState}
        groupingColDef={{ leafField: "lastName" }}
      />
    </Box>
  );
};

export default CollegeEvalsGrid;
