import { Box, SelectChangeEvent } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridColumnVisibilityModel,
  GridFilterModel,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import AllProEvalsGridToolbar from "./AllProEvalsGridToolbar";
import { EvalTypeEnum, useGetProEvaluationListQuery } from "../../../../graphql/generated/graphql";
import getProEvalColumns, { AllProEvalRow } from "./AllProEvalColumns";

import { useDataGridStyles } from "../../../common/grids/useDataGridStyles";
import { getScoutDisplayName } from "../../../../utils/user";
import { getDefaultSeason } from "../../../../utils/season";
import { getPlayerStatus } from "../../../../utils/players";

export const AllProEvalsGrid = () => {

  const [gridGroupBy, setGridGroupBy] = useState("none");
  const [gridSeason, setGridSeason] = useState(
    getDefaultSeason().toString()
  );
  
  // get scout evaluations
  const { data: scoutEvals, loading: scoutEvalsLoading } =
    useGetProEvaluationListQuery({
      variables: { group: EvalTypeEnum.PRO_SCOUT, season: Number(gridSeason) },
    });

  const evals = scoutEvals?.evals;

  // default filter to current season
   const [filter, setFilter] = useState<GridFilterModel>({
    items: [
      {
        columnField: "season",
        operatorValue: "equals",
        value: getDefaultSeason().toString(),
      },
    ],
  });
  const [rowGroupingModel, setRowGroupingModel] =
    useState<GridRowGroupingModel>(["none"]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      name: true,
      playerId: false,
    });

  const handleGroupChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridGroupBy(value);
    setRowGroupingModel([value]);

    if (value != "none") {
      setColumnVisibilityModel({ name: false, playerId: false });
    } else {
      setColumnVisibilityModel({ name: true, playerId: false });
    }
  };
  const [pageSize, setPageSize] = useState(10);

  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["name"],
      },
      sorting: {
        sortModel: [{ field: "grade", sort: "desc" }],
      },
      columns: {
        columnVisibilityModel: columnVisibilityModel,
      },
      filter: {
        filterModel: filter,
      },
    },
  });
  const handleSeasonChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridSeason(value);

    // apply grid filter
    setFilter({
      items: [
        {
          columnField: "season",
          operatorValue: "equals",
          value: value,
        },
      ],
    });
  };

  // Columns
  const columns: GridColDef<AllProEvalRow>[] = getProEvalColumns();

  // Rows
  const rows: AllProEvalRow[] = [];

  evals?.forEach((e) => {
    rows.push({
      rowId: e.id,
      season: e.season,
      position: e.position.shortName,
      fits: e.fits?.map((f) => f.fit.name),
      alerts: e.alerts?.map((f) => f.alert.abbreviation),
      playerId: e.player.id,
      firstName: e.player.firstName,
      lastName: e.player.lastName,
      headshotUrl: e.player.headshotUrl,
      grade: e.scoutGrade?.grade,
      scout: getScoutDisplayName(e.user.firstName ?? "", e.user.lastName ?? ""),
      jersey: e.player.jersey,
      lastUpdated: e.lastUpdated,
      division: e.player.club?.division,
      clubCode: e.player.club?.clubCode,
      clubHome: e.player.club?.clubCode,
      clubId: e.player.club?.id,
      freeAgencyStatus: e.player.freeAgencyStatus,
      gameCount: e.gameGrades.length,
      status: e.status,
      hasBeenReviewed: e.finalizedOn != null,
      playerStatus: getPlayerStatus(e.player.status)
    });
  });

  return (
    <Box flex={1}>
      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
        }}
        columnVisibilityModel={columnVisibilityModel}
        getRowId={(row) => row.rowId}
        autoHeight
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 75]}
        rows={rows}
        rowHeight={45}
        loading={scoutEvalsLoading}
        disableDensitySelector
        disableColumnSelector
        disableColumnResize
        disableColumnReorder
        disableColumnPinning
        disableSelectionOnClick
        components={{
          Toolbar: () => {
            return (
              <AllProEvalsGridToolbar
                handleSeasonChange={handleSeasonChange}
                handleGroupChange={handleGroupChange}
                gridGroupBy={gridGroupBy}
                gridSeason={gridSeason}
                hideGroupBy={false}
              />
            );
          },
        }}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        apiRef={apiRef}
        initialState={initialState}
        groupingColDef={{ leafField: "name" }}
        filterModel={filter}
        onFilterModelChange={(newFilterModel) => {
          setFilter(newFilterModel);
        }}
      />
    </Box>
  );
};

export default AllProEvalsGrid;
