import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { GroupBy } from "@sumer/shared/components/clubs/RosterGridToolbar";

interface ProEvalsGridToolbarProps {
  handleGroupChange: (event: SelectChangeEvent) => void;
  gridGroupBy: string;
  hideGroupBy: boolean;
  handleTagChange: (event: SelectChangeEvent) => void;
  gridTagSelected: string;
}

export const ProEvalsToolbar = ({
  handleGroupChange,
  gridGroupBy,
  hideGroupBy,
  handleTagChange,
  gridTagSelected
}: ProEvalsGridToolbarProps) => {
  
  return (
    <Stack
      flexDirection="row"
      mb={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" justifyContent="space-between" flex={1}>
        <Box>
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{ marginRight: 10 }} />
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{ disableToolbarButton: true }}
              excelOptions={{ allColumns: true }}
            />
          </GridToolbarContainer>
        </Box>
          <Box display="flex" justifyContent="flex-end" flex={1} mr={1}>
           <FormControl size="small">
           <Select
              size="small"
              value={gridTagSelected}
              sx={{ width: 225 }}
              color="primary"
              onChange={handleTagChange}
            >
              <MenuItem key={1} value={"none"}>
                <TagSelect label="None" />
              </MenuItem>
              <MenuItem key={1} value={"shortList"}>
                <TagSelect label="Short List" />
              </MenuItem>
              <MenuItem key={2} value={"tradeCandidate"}>
                <TagSelect label="Trade Candidates" />
              </MenuItem>
              <MenuItem key={3} value={"cutdownPriority"}>
                <TagSelect label="Cutdown Priorities" />
              </MenuItem>
            </Select>
           </FormControl>
           </Box>
        {!hideGroupBy && (
          <FormControl size="small">
            <Select
              size="small"
              value={gridGroupBy}
              sx={{ width: 250 }}
              color="primary"
              onChange={handleGroupChange}
            >
              <MenuItem key={1} value={"none"}>
                <GroupBy label="None" />
              </MenuItem>
              <MenuItem key={2} value={"status"}>
                <GroupBy label="Status" />
              </MenuItem>
              <MenuItem key={3} value={"club"}>
                <GroupBy label="Club" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};
const TagSelect = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography fontSize={".9rem"} mr={0.75}>Tag: </Typography>
      <Typography fontSize={".9rem"} fontWeight={700}>{props.label}</Typography>
    </Stack>
  );
};
export default ProEvalsToolbar;
