import { Box, SelectChangeEvent } from "@mui/material";
import {
  FreeAgencyPlayerFragment,
  useGetPlayerSageQuery,
} from "../../../graphql/generated/graphql";
import {
  DataGridPremium,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import getFreeAgentPlayerColumns, {
  FreeAgentPlayerRow,
} from "./FreeAgencyColumns";
import FreeAgencyGridToolbar from "./FreeAgencyGridToolbar";
import { getPlayerAge } from "../../../utils/players";
import { useDataGridStyles } from "../../common/grids/useDataGridStyles";
import { getPlayerMeasures } from "../../common/measures/useGetPlayerMeasures";
import { getDefaultSeason } from "../../../utils/season";
import { formatDate } from "@sumer/shared/utils/dates";

export const FreeAgencyGrid = (props: {
  players: FreeAgencyPlayerFragment[] | undefined;
  loading: boolean;
}) => {
  // get SAGE
  const playerIds = props.players?.map((e) => e.id);
  const playerSage = useGetPlayerSageQuery({
    variables: { playerIds: playerIds ?? [], season: getDefaultSeason() },
    skip: playerIds == undefined,
  });

  const [gridGroupBy, setGridGroupBy] = useState("none");
  const [rowGroupingModel, setRowGroupingModel] =
    useState<GridRowGroupingModel>(["none"]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      name: true,
      playerId: false,
      positionSort: false,
    });
  const handleGroupChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridGroupBy(value);
    setRowGroupingModel([value]);

    if (value != "none") {
      setColumnVisibilityModel({
        name: false,
        playerId: false,
        positionSort: false,
      });
    } else {
      setColumnVisibilityModel({
        name: true,
        playerId: false,
        positionSort: false,
      });
    }
  };
  const [pageSize, setPageSize] = useState(10);
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["name"],
      },
      sorting: {
        sortModel: [{ field: "grade", sort: "desc" }],
      },
      columns: {
        columnVisibilityModel: columnVisibilityModel,
      },
    },
  });

  // Columns
  const columns: GridColDef<FreeAgentPlayerRow>[] = getFreeAgentPlayerColumns();

  // Rows
  const rows: FreeAgentPlayerRow[] = [];

  props.players?.forEach((e) => {
    const primaryEval = e.evaluations.find((e) => e.isPrimary);
    const sage = playerSage.data?.playerSage.find(
      (w) => w.marvalPlayerId == e.id
    );

    const workoutMeasures = getPlayerMeasures(e.workouts);
    rows.push({
      rowId: e.id,
      sumerGeneral: e.sumerGeneralPosition?.shortName ?? "",
      sumerAtlas: e.sumerAtlasPosition?.shortName,
      fits: primaryEval?.fits?.map((f) => f.fit.name),
      alerts: primaryEval?.alerts?.map((f) => f.alert.abbreviation),
      playerId: e.id,
      firstName: e.firstName,
      lastName: e.lastName,
      headshotUrl: e.headshotUrl,
      grade: primaryEval?.scoutGrade?.grade,
      jersey: e.jersey,
      clubCode: e.club?.clubCode,
      clubId: e.club?.id,
      freeAgencyStatus: e.freeAgencyStatus,
      draftYear: e.draftYear,
      age: e.latestBirthDate
        ? getPlayerAge(formatDate(new Date(e.latestBirthDate), "MM/DD/YYYY"))
        : undefined,
      positionSort: e.sumerGeneralPosition?.sort ?? 99,
      fallbackHeight: e.height,
      fallbackWeight: e.weight,
      fallbackSpeed: e.speed,
      height: workoutMeasures.height,
      weight: workoutMeasures.weight,
      speed: workoutMeasures.speed,
      arm: workoutMeasures.arm,
      hand: workoutMeasures.hand,
      wing: workoutMeasures.wing,
      wsd: workoutMeasures.wsd,
      sage: sage?.gapypocPrediction,
    });
  });

  return (
    <Box flex={1}>
      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
        }}
        columnVisibilityModel={columnVisibilityModel}
        getRowId={(row) => row.rowId}
        autoHeight
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 75]}
        rows={rows}
        rowHeight={45}
        loading={props.loading}
        disableDensitySelector
        disableColumnSelector
        disableColumnResize
        disableColumnReorder
        disableColumnPinning
        disableSelectionOnClick
        components={{
          Toolbar: () => {
            return (
              <FreeAgencyGridToolbar
                handleGroupChange={handleGroupChange}
                gridGroupBy={gridGroupBy}
                hideGroupBy={false}
              />
            );
          },
        }}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        apiRef={apiRef}
        initialState={initialState}
        groupingColDef={{ leafField: "name" }}
      />
    </Box>
  );
};

export default FreeAgencyGrid;
