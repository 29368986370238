import { EvalSummaryTraitGradeFragment, EvalTypeEnum, ScoutTypeEnum, TraitGroupFragment } from "../graphql/generated/graphql";
import { BigBoardEvalRow } from "../components/home/all/BigBoardEvalsCollege/BigBoardEvalColumns";
import { BigBoardEvalProRow } from "../components/home/all/BigBoardEvalsPro/BigBoardEvalColumnsPro";
import _ from "lodash";

export const GradeScale = {
  Durability: "Durability",
  Trait: "Trait",
  Game: "Game",
  Evaluation: "Evaluation",
  Portal: "Portal",
};
export function filterGroups(groups: TraitGroupFragment[], position: string) {
  const returnGroups = new Set<TraitGroupFragment>();
  groups?.forEach((grp) => {
    grp.traitGroupPositions.forEach((m) => {
      if (m.positionId == position) {
        returnGroups.add(grp);
      }
    });
  });

  return Array.from(returnGroups);
}

export function getEvaluationGradeForeColor(grade: number) {
  if (grade == 10) return "white";

  return "inherit";
}
// should we store this in the scout grade table? Should we update to match design colors?
export function getEvaluationGradeBackColor(grade: number) {
  if (grade == 10) return "#175CD3";
  if (grade == 9) return "#53B1FD";
  if (grade == 8) return "#84CAFF";
  if (grade < 8 && grade >= 6.6) return "#30B55D";
  if (grade <= 6.5 && grade >= 6.0) return "#86CB3C";
  if (grade >= 5.8 && grade <= 5.9) return "#BDB4FE";
  if (grade == 5.7) return "#F7B27A";
  if (grade >= 5.5 && grade <= 5.6) return "#FDE272";
  if (grade == 5.0) return "#84CAFF"; //portal grade
  if (grade == 4.8) return "#30B55D"; //portal grade
  if (grade == 4.6) return "#86CB3C"; //portal grade
  if (grade == 4.4) return "#BDB4FE"; //portal grade
  if (grade == 4.2) return "#F7B27A"; //portal grade
  if (grade == 4.0) return "#FDE272"; //portal grade
  if (grade == 2.0) return "#FCFCFD";
  if (grade == 1.0) return "#C1C7CD";

  return "white";
}

export function getTraitGradeBackColor(grade: number) {
  switch (grade) {
    case 10:
      return "#175CD3";
    case 9:
      return "#53B1FD";
    case 8:
      return "#84CAFF";
    case 7:
      return "#30B55D";
    case 6:
      return "#86CB3C";
    case 5:
      return "#BDB4FE";
    case 4:
      return "#F7B27A";
    case 3:
      return "#FDE272";
    case 2:
      return "lightgrey";
    case 1:
      return "darkgrey";
    default:
      return "white";
  }
}
export function getDurabilityGradeBackColor(grade: number) {
  switch (grade) {
    case 8:
      return "#84CAFF";
    case 6:
      return "#86CB3C";
    case 5:
      return "#BDB4FE";
    case 3:
      return "#FDE272";
    default:
      return "white";
  }
}

export function getAlertBackColor(alert: string) {
  switch (alert) {
    case "F":
      return "#175CD3";
    case "G":
    case "KK":
      return "#84CAFF";
    case "K":
      return "#30B55D";
    case "D":
    case "U":
    case "W":
    case "C":
    case "M":
    case "A":
      return "orange";
    case "X":
      return "indianred";
    default:
      return "white";
  }
}
export function getAlertForeColor(alert: string) {
  switch (getAlertBackColor(alert)) {
    case "#175CD3":
    case "indianred":
      return "white";
    default:
      return "black";
  }
}

export const sortBy = (
  rows: BigBoardEvalRow[] | BigBoardEvalProRow[],
  key: keyof BigBoardEvalRow | keyof BigBoardEvalProRow,
  order = "ascending"
) => {
  return rows.sort((a, b) => {
    const curr = Number(a[key as keyof (BigBoardEvalRow | BigBoardEvalProRow)]);
    const next = Number(b[key as keyof (BigBoardEvalRow | BigBoardEvalProRow)]);

    if (curr && next) {
      return order === "descending" ? next - curr : curr - next;
    } else if (curr && !next) {
      return -1;
    } else if (!curr && next) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const assignDefaultRank = (
  rows: BigBoardEvalRow[] | BigBoardEvalProRow[]
) => {
  let rank = 1;
  let currentGrade = rows[0]?.grade;
  rows.forEach((r) => {
    if (r.grade === undefined) return;
    if (r.grade && r.grade != currentGrade) {
      rank = 1;
      currentGrade = r.grade;
    }
    if (!r.rank) {
      r.rank = rank;
      rank++;
    }
  });
};

export const getMissingTraitCount = (
  evalTraits: EvalSummaryTraitGradeFragment[],
  traits: TraitGroupFragment[] | undefined,
  positionId: string
) => {
  const positionTraits: string[] = [];
  const missingTraits: string[] = [];
  _.forEach(traits, function (traitGroup) {
    //traits valid for position
    const pos = traitGroup.traitGroupPositions
      .filter((t) => t.positionId == positionId)
      .map((t) => t.traitDefinition.id);
    positionTraits.push(...pos);
  });

  _.forEach(positionTraits, function (item) {
    if (
      !evalTraits.find(
        (et) => et.traitDefinition.id === item && et.scoutGradeId
      )
    ) {
      missingTraits.push(item);
    }
  });
  const completedTraits = positionTraits.length - missingTraits.length;
  return missingTraits.length == positionTraits.length
    ? "All"
    : `${completedTraits}/${positionTraits.length}`;
};

export const getEvalTypeDisplay = (evaltype: EvalTypeEnum | undefined) => {
  if (!evaltype) return "";
  switch (evaltype) {
    case EvalTypeEnum.COLLEGE_SCOUT:
      return "NFL Draft";
    case EvalTypeEnum.PRO_SCOUT:  
      return "Pro";
    case EvalTypeEnum.COLLEGE_CROSS_CHECK:
    case EvalTypeEnum.PRO_CROSS_CHECK:
      return "X-C";
    case EvalTypeEnum.COLLEGE_PORTAL:
      return "NCAA/Portal";
    default: "";
  }
};

export const getScoutAssignmentTypeDisplay = (evaltype: ScoutTypeEnum | undefined) => {
  if (!evaltype) return "";
  switch (evaltype) {
    case ScoutTypeEnum.COLLEGE:
      return "NFL Draft";
    case ScoutTypeEnum.PRO:  
      return "Pro";
    case ScoutTypeEnum.CROSS_CHECK:
      return "X-C";
    case ScoutTypeEnum.PORTAL:
      return "NCAA/Portal";
    default: "";
  }
};

export const getScoutAbbreviatedName = (firstName: string | undefined, lastName: string | undefined) => {
  if (!firstName || !lastName) return "";
  return `${firstName.substring(0,1)}${lastName.substring(0,3)}`.toUpperCase();
}