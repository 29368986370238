import {
  Box,
  Button,
  CircularProgress,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useRouter } from "next/navigation";
import SimIcon from "../../public/sim_icon.svg";
import SimFootball from "../../public/sim_football.svg";
import { HomePageHeader } from "../../components/HomePageHeader";

export const SumerScout = () => {
  const router = useRouter();
  const theme = useTheme();
  const [navigating, setNavigating] = useState(false);

  const navigateToSearch = () => {
    setNavigating(true);
    router.push(`/player/search`);
  };

  return (
    <>
      <HomeContainer>
        <Stack
          borderRadius="4px"
          flex={1}
          bgcolor={theme.palette.common.white}
          boxShadow={`8px 8px 10px 0px ${theme.palette.grey[300]}`}
        >
          <HomePageHeader
            icon={SimIcon}
            image={SimFootball}
            text={"Sumer Scout"}
          />
          <Box pb={5}>
            <Stack padding={5}>
              <Typography
                fontSize="1.5em"
                fontWeight="bold"
                color={theme.palette.grey[800]}
              >
                Find a Player
              </Typography>
              <Typography mt={1} mb={5} fontSize="1em">
                Find a player to evaluate, grade, or gain information about.
                View their stats, injuries, transactions, and more.
              </Typography>
              <StartButton onClick={navigateToSearch} disabled={navigating}>
                {navigating ? "Entering Search..." : "Search Players"}
                {navigating && (
                  <CircularProgress
                    color="secondary"
                    size="1.25em"
                    sx={{ marginLeft: 1 }}
                  />
                )}
              </StartButton>
            </Stack>
          </Box>
        </Stack>
      </HomeContainer>
    </>
  );
};

const HomeContainer = styled("div")(() => ({
  display: "flex",
  flex: 1,
  alignSelf: "stretch",
  marginTop: 30,
  marginBottom: 20,
}));

const StartButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  backgroundColor: theme.palette.grey[800],
  borderRadius: 5,
  fontSize: "1em",
  fontWeight: 400,
  textTransform: "none",
  paddingLeft: 20,
  paddingRight: 20,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.grey[800],
  },
  "&:disabled": {
    color: theme.palette.common.white,
  },
  maxWidth: 250,
}));

export default SumerScout;
