import { GridColDef } from "@mui/x-data-grid-premium";
import { ClubCell, NameCell, RankCell } from "../../../common/tableCells";
import { CellValue } from "@sumer/shared/components/design/ColumnValues";
import { getPlayerHeight } from "../../../../utils/players";
import { FreeAgencyCell } from "../../../common/tableCells/FreeAgencyCell";
import { Measurable } from "../../../common/measures/measures";
import { GradeCell } from "../../../common/tableCells/GradeCell";

export interface BigBoardEvalProRow {
  rowId: string;
  position?: string;
  playerId: string;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  clubId?: string | null | undefined;
  clubCode?: string;
  grade?: number;
  rank?: number | null;
  division?: string;
  jersey?: string | null | undefined;
  freeAgencyStatus: string | null | undefined;
  fallbackHeight?: number | null;
  fallbackWeight?: number | null;
  fallbackSpeed?: number | null;
  height: Measurable | undefined;
  weight: Measurable | undefined;
  speed: Measurable | undefined;
  hand: Measurable | undefined;
  arm: Measurable | undefined;
  wing: Measurable | undefined;
  wsd: Measurable | undefined;
  __reorder__: string;
}

const getBigBoardEvalProColumns = (): GridColDef<BigBoardEvalProRow>[] => {
  const columns: GridColDef<BigBoardEvalProRow>[] = [
    {
      field: "division",
      headerName: "Division",
      headerClassName: "column-header",
      type: "string",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.division,
      renderCell: (params) => {
        if (!params.row.division) return null;
        return <CellValue value={params.row.division}></CellValue>;
      },
      valueGetter: (params) => params.row.division,
    },
    {
      field: "position",
      headerName: "Pos",
      width: 60,
      headerClassName: "column-header",
      groupable: false,
      groupingValueGetter: (params) => params.row.position,
      renderCell: (params) => {
        if (!params.row.position) return null;
        return <CellValue value={params.row.position}></CellValue>;
      },
      valueGetter: (params) => params.row.position,
    },
    {
      field: "name",
      headerName: "Player",
      width: 220,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.lastName,
      renderCell: (params) => {
        if (!params.row.lastName) return null;
        return (
          <NameCell
            id={params.row.playerId}
            jersey={params.row.jersey ?? "0"}
            first={params.row.firstName}
            last={params.row.lastName}
            headshot={params.row.headshotUrl}
            anchor
            newTab
          />
        );
      },
      valueGetter: (params) =>
        params.row.lastName &&
        params.row.lastName + ", " + params.row.firstName,
    },
    {
      field: "club",
      headerName: "Club",
      width: 95,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return !params.row.clubCode ? "Free Agent" : params.row.clubCode;
      },
      renderCell: (params) => {
        if (!params.row.clubCode) return "";
        return (
          <ClubCell
            id={params.row.clubId ?? ""}
            clubCode={params.row.clubCode}
            clubName={params.row.clubCode}
            displayLogo={false}
          />
        );
      },
      valueGetter: (params) => params.row.clubCode,
    },
    {
      field: "freeAgencyStatus",
      headerName: "F.Agy",
      width: 60,
      headerClassName: "column-header",
      groupingValueGetter: (params) => {
        return params.row.freeAgencyStatus;
      },
      renderCell: (params) => {
        return <FreeAgencyCell status={params.row.freeAgencyStatus} />;
      },
      valueGetter: (params) => params.row.freeAgencyStatus,
    },
    {
      field: "rank",
      headerName: "Rank",
      width: 60,
      disableExport: true,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.row.playerId) return null;
        return <RankCell rank={params.row.rank} />;
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      minWidth: 60,
      headerClassName: "column-header",
      disableColumnMenu: true,
      type: "number",
      groupingValueGetter: (params) => params.row.grade,
      renderCell: (params) => {
        return  <GradeCell grade={params.row.grade} />
      },
      valueGetter: (params) => params.row.grade?.toFixed(1),
    },
    {
      field: "height",
      headerName: "H",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          params.row.height?.value ?? getPlayerHeight(params.row.fallbackHeight)
        );
      },
      valueGetter: (params) =>
        params.row.height?.value ?? getPlayerHeight(params.row.fallbackHeight),
    },
    {
      field: "weight",
      headerName: "W",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.weight?.value ?? params.row.fallbackWeight;
      },
      valueGetter: (params) =>
        params.row.weight?.value ?? params.row.fallbackWeight,
    },
    {
      field: "speed",
      headerName: "S",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.speed?.value ?? params.row.fallbackSpeed;
      },
      valueGetter: (params) =>
        params.row.speed?.value ?? params.row.fallbackSpeed,
    },
    {
      field: "hand",
      headerName: "Hand",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.hand?.value;
      },
      valueGetter: (params) => params.row.hand?.value,
    },
    {
      field: "arm",
      headerName: "Arm",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.arm?.value;
      },
      valueGetter: (params) => params.row.arm?.value,
    },
    {
      field: "wing",
      headerName: "Wing",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.wing?.value;
      },
      valueGetter: (params) => params.row.wing?.value,
    },
    {
      field: "wsd",
      headerName: "WSD",
      flex: 1,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.wsd?.value;
      },
      valueGetter: (params) => params.row.wsd?.value,
    },
  ];

  return columns;
};

export default getBigBoardEvalProColumns;
