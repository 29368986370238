import _ from "lodash";
import { AssignedPlayerFragment } from "../../graphql/generated/graphql";


export  function getDistinctAlerts(
    player: AssignedPlayerFragment | undefined,
    asStringList?: boolean
  ) {
    if (player) {
        if (player.evaluations?.length == 0) return "";
    }
    const evalAlerts = player?.evaluations?.filter(
        (e) => e.alerts && e.alerts.length > 0
    );
    
    const alerts = _.orderBy(
      evalAlerts?.flatMap((a) => a.alerts.flatMap((a) => a.alert)),
      ["sort"]
    );
    const seen = new Set<string>();
    const uniqueAlerts = alerts?.filter((a) => {
      if (seen.has(a.abbreviation)) return false;
      seen.add(a.abbreviation);
      return true;
    });
  
    if (asStringList) return uniqueAlerts?.map((a) => a.abbreviation).join(",");
  
    return uniqueAlerts;
  }

  
export function getDistinctFits(
    player: AssignedPlayerFragment | undefined,
    asStringList?: boolean
  ) {
    if (player) {
        if (player.evaluations && player.evaluations.length == 0) return "";
    }
    const playerEvals = player?.evaluations?.filter(
        (e) => e.fits && e.fits.length > 0
    );
  
    const orderedEvals = _.orderBy(
      playerEvals,
      ["season", "lastUpdated"],
      ["desc", "desc"]
    ) as typeof playerEvals;
  
    const evalFits = orderedEvals?.flatMap((a) => a.fits);
  
    const orderedFits = _.orderBy(evalFits, ["priority"], ["asc"]);
    const seen = new Set<string>();
    const uniqueFits = orderedFits?.filter((a) => {
      if (seen.has(a.fit.name)) return false;
      seen.add(a.fit.name);
      return true;
    });
  
    if (asStringList) return uniqueFits?.map((a) => a.fit.name).join(", ");
  
    return uniqueFits;
  }