import { Stack, Typography, styled, useTheme } from "@mui/material";
import { TransactionsGrid } from "./TransactionsGrid";

export const Transactions = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <TransactionsContainer>
      <Stack flex={1}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography fontSize={28} fontWeight={700} color={colors.grey[800]}>
            Transactions
          </Typography>
        </Stack>
        <Stack mt={3} flex={1}>
          <TransactionsGrid />
        </Stack>
      </Stack>
    </TransactionsContainer>
  );
};

const TransactionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "40px 40px 10px 40px",
  marginTop: "20px",
  marginBottom: "40px",
  backgroundColor: theme.palette.common.white,
  borderRadius: 4,
  boxShadow: `8px 8px 10px 0px ${theme.palette.grey[300]}`,
}));