import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import React from "react";

interface CollegeEvalsGridToolbarProps {
  handleGroupChange: (event: SelectChangeEvent) => void;
  gridGroupBy: string;
  displayRecommendationButton?: boolean;
  hideGroupBy: boolean;
}

const CollegeEvalsToolbar = ({
  handleGroupChange,
  gridGroupBy,
  hideGroupBy,
}: CollegeEvalsGridToolbarProps) => {
  return (
    <Stack
      flexDirection="row"
      mb={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" justifyContent="space-between" flex={1}>
        <Box>
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{ marginRight: 10 }} />
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{ disableToolbarButton: true }}
              excelOptions={{ allColumns: true }}
            />
          </GridToolbarContainer>
        </Box>
        {!hideGroupBy && (
          <FormControl size="small">
            <Select
              size="small"
              value={gridGroupBy}
              sx={{ width: 250 }}
              color="primary"
              onChange={handleGroupChange}
            >
              <MenuItem key={1} value={"none"}>
                <GroupBy label="None" />
              </MenuItem>
              <MenuItem key={2} value={"school"}>
                <GroupBy label="School" />
              </MenuItem>
              <MenuItem key={3} value={"status"}>
                <GroupBy label="Status" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};

const GroupBy = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography mr={0.75} fontSize={".9rem"}>Group By: </Typography>
      <Typography fontWeight={700} fontSize={".9rem"}>{props.label}</Typography>
    </Stack>
  );
};

export default CollegeEvalsToolbar;
