import { Box, SelectChangeEvent } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowGroupingModel,
  GridSortModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { ProEvalRow, getProEvalColumns } from "./ProEvalColumns";
import { ProEvalsToolbar } from "./ProEvalsToolbar";
import { useDataGridStyles } from "../../common/grids/useDataGridStyles";
import { useState } from "react";
import useGetProEvaluationsForUser from "./useGetProEvaluationsForUser";

export const ProEvalsGrid = () => {

  const [gridGroupBy, setGridGroupBy] = useState("none");
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "grade", sort: "desc" }]);
  const [tagSelected, setTagSelected] = useState("none");

  const [pageSize, setPageSize] = useState(10);
  const [rowGroupingModel, setRowGroupingModel] =
    useState<GridRowGroupingModel>(["none"]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      lastName: true,
      season: false,
      cutdownPriority: false,
      shortList: false,
      tradeCandidate: false,
     });
    const [filter, setFilter] = useState<GridFilterModel>({
      items: [],
    });

  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["none"],
      },
      sorting: {
        sortModel: [{ field: "grade", sort: "desc" }],
      },
      filter: {
        filterModel: filter,
      },
    },
  });

  const handleTagChange = (event: SelectChangeEvent) => {
    setTagSelected(event.target.value);
    switch(event.target.value) {
     case "cutdownPriority": 
      // apply grid filter
      setFilter({
        items: [
          {
            columnField: "cutdownPriority",
            operatorValue: "equals",
            value: "true",
          }
        ],
      });
      setSortModel([{ field: "cutdownCutPercentage", sort: "desc" }]);
      break;
      case "shortList":
        setFilter({
          items: [
            {
              columnField: "shortList",
              operatorValue: "equals",
              value: "true",
            }
          ],
        });
        setSortModel([{ field: "grade", sort: "desc" }]);
        break;
      case "tradeCandidate":
        setFilter({
          items: [
            {
              columnField: "tradeCandidate",
              operatorValue: "equals",
              value: "true",
            }
          ],
        });
        setSortModel([{ field: "grade", sort: "desc" }]);
        break;
      case "none":
        setSortModel([{ field: "grade", sort: "desc" }]);
        setFilter({items:[]})
        break;
      default:
      setSortModel([{ field: "grade", sort: "desc" }]);
      setFilter({items:[]})
      break;
    }
  }

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };
  const handleGroupChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGridGroupBy(value);
    setRowGroupingModel([value]);

    if (value != "none") {
      setColumnVisibilityModel({
        lastName: false,
        positionSort: false,
        playerId: false,
        season: false,
        cutdownPriority: false,
        shortList: false,
        tradeCandidate: false,
      });
    } else {
      setColumnVisibilityModel({
        lastName: true,
        positionSort: false,
        playerId: false,
        season: false,
        cutdownPriority: false,
        shortList: false,
        tradeCandidate: false,
      });
    }
  };

  // Columns
  const columns: GridColDef<ProEvalRow>[] = getProEvalColumns();

  // Rows
  const { rows, loading } = useGetProEvaluationsForUser();

  return (
    <Box flex={1}>
      <DataGridPremium
        sx={{
          ...useDataGridStyles(),
          "& .cut-cell": {
            backgroundColor: "aliceblue",
            fontWeight: "700",
          },
        }}
        columns={columns}
        sortingOrder={["desc", "asc"]}
        columnVisibilityModel={columnVisibilityModel}
        getRowId={(row) => row.rowId}
        rowHeight={40}
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 75]}
        rows={rows}
        loading={loading}
        components={{
          Toolbar: () => {
            return (
              <ProEvalsToolbar
                handleTagChange={handleTagChange}
                handleGroupChange={handleGroupChange}
                gridGroupBy={gridGroupBy}
                hideGroupBy={false}
                gridTagSelected={tagSelected}
              />
            );
          },
        }}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        apiRef={apiRef}
        initialState={initialState}
        groupingColDef={{ leafField: "lastName" }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        filterModel={filter}
        onFilterModelChange={(newFilterModel) => {
          setFilter(newFilterModel);
        }}
      />
    </Box>
  );
};


export default ProEvalsGrid;
