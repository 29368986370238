import { useGetSchoolsForLookupQuery } from "../../graphql/generated/graphql";

export const useGetSchoolsForLookup = (divisions?: string[]) => {
  // get schools for lookup
  const schoolFilter = divisions ?? ["1A", "1AA", "2"];
  const { data, loading } = useGetSchoolsForLookupQuery({
    variables: {
      division: schoolFilter,
    },
  });
  return { schools: data?.schools, loading };
};
