import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import React from "react";

export const RosterGridToolbar = (props: {
  handleGroupChange: (event: SelectChangeEvent) => void;
  gridGroupBy: string;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Stack
      flexDirection="row"
      mb={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" justifyContent="space-between" flex={1}>
        <Box>
          <GridToolbarContainer>
            <GridToolbarQuickFilter />
          </GridToolbarContainer>
        </Box>
        <Stack flexDirection="row" alignItems="end">
          <Box mr={3}>
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{ disableToolbarButton: true }}
            />
          </Box>
          <FormControl size="small">
            <Select
              size="small"
              value={props.gridGroupBy}
              sx={{ width: 225 }}
              color="primary"
              onChange={props.handleGroupChange}
            >
              <MenuItem key={1} value={"none"}>
                <GroupBy label="None" />
              </MenuItem>
              <MenuItem key={3} value={"positionGroup"}>
                <GroupBy label="Position Group" />
              </MenuItem>
              <MenuItem key={4} value={"sideOfBall"}>
                <GroupBy label="Side of Ball" />
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
};
export const GroupBy = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography fontSize=".9rem" mr={0.75}>Group By: </Typography>
      <Typography fontSize=".9rem" fontWeight={700}>{props.label}</Typography>
    </Stack>
  );
};
