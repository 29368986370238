import { RefObject, useLayoutEffect, useRef, useState } from "react";

export const useParentHeightBoundary = (): [
  RefObject<HTMLDivElement>,
  number
] => {
  const heightRef = useRef<HTMLDivElement>(null);
  const [heights, setHeights] = useState({
    containerHeight: 100,
    windowHeight: window.innerHeight,
  });

  // Set the initial boundary height
  useLayoutEffect(() => {
    if (heightRef.current && heightRef.current.parentElement) {
      const { height } =
        heightRef.current.parentElement.getBoundingClientRect();
      setHeights({
        containerHeight: height,
        windowHeight: window.innerHeight,
      });
    }
  }, []);

  // Update boundary height as window is resized
  useLayoutEffect(() => {
    const handleResize = () => {
      const newWindowHeight = window.innerHeight;
      const windowChangeSize = newWindowHeight - heights.windowHeight;
      if (newWindowHeight != heights.windowHeight) {
        setHeights({
          containerHeight: heights.containerHeight + windowChangeSize,
          windowHeight: newWindowHeight,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  });

  return [heightRef, heights.containerHeight];
};

// Does not react to window resize
export const useParentHeightBoundaryStatic = (): [
  RefObject<HTMLDivElement>,
  number
] => {
  const heightRef = useRef<HTMLDivElement>(null);
  const [heights, setHeights] = useState({
    containerHeight: 100,
    windowHeight: window.innerHeight,
  });

  // Set the initial boundary height
  useLayoutEffect(() => {
    if (heightRef.current && heightRef.current.parentElement) {
      const { height } =
        heightRef.current.parentElement.getBoundingClientRect();
      setHeights({
        containerHeight: height,
        windowHeight: window.innerHeight,
      });
    }
  }, []);

  return [heightRef, heights.containerHeight];
};
