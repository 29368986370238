import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import React from "react";
import { getDefaultSeason, getSeasons } from "../../../../utils/season";

const AllProEvalsGridToolbar = (props: {
  handleGroupChange: (event: SelectChangeEvent) => void;
  gridGroupBy: string;
  hideGroupBy: boolean;
  handleSeasonChange?: (event: SelectChangeEvent) => void;
  gridSeason?: string;
}) => {
  const defaultSeason = getDefaultSeason();
  return (
    <Stack
      flexDirection="row"
      mb={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" justifyContent="space-between" flex={1}>
        <Box>
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{ marginRight: 10 }} />
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{ disableToolbarButton: true }}
              excelOptions={{ allColumns: true }}
            />
          </GridToolbarContainer>
        </Box>
        {props.gridSeason && (
        <Box display="flex" justifyContent="flex-end" flex={1} mr={1}>
          <FormControl size="small">
            <Select
              size="small"
              value={props.gridSeason}
              color="primary"
              sx={{ minWidth: 125 }}
              onChange={props.handleSeasonChange}
            >
              {getSeasons(defaultSeason - 1, defaultSeason).map(
                (season) => (
                  <MenuItem key={season} value={season.toString()}>
                    <SeasonSelect label={season.toString()} />
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        )}
        {!props.hideGroupBy && (
          <FormControl size="small">
            <Select
              size="small"
              value={props.gridGroupBy}
              sx={{ width: 200 }}
              color="primary"
              onChange={props.handleGroupChange}
            >
              <MenuItem key={1} value={"none"}>
                <GroupBy label="None" />
              </MenuItem>
              <MenuItem key={2} value={"position"}>
                <GroupBy label="Position" />
              </MenuItem>
              <MenuItem key={3} value={"club"}>
                <GroupBy label="Club" />
              </MenuItem>
              <MenuItem key={4} value={"scout"}>
                <GroupBy label="Scout" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};
const SeasonSelect = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography fontSize={".9rem"} mr={0.75}>Season: </Typography>
      <Typography fontSize={".9rem"} fontWeight={700}>{props.label}</Typography>
    </Stack>
  );
};
const GroupBy = (props: { label: string }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography fontSize={".9rem"}  mr={0.75}>Group By: </Typography>
      <Typography fontSize={".9rem"}  fontWeight={700}>{props.label}</Typography>
    </Stack>
  );
};

export default AllProEvalsGridToolbar;
