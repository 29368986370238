import { Stack, Typography } from "@mui/material";
import {
  getFreeAgencyStatuForeColor,
  getFreeAgencyStatusBackColor,
} from "../../../utils/players";
import { FreeAgencyStatus } from "../../../graphql/generated/graphql";

export const FreeAgencyCell = (props: {
  status?: string | null | undefined;
}) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography
        fontSize=".8rem"
        fontWeight={500}
        bgcolor={getFreeAgencyStatusBackColor(props.status ?? "")}
        color={getFreeAgencyStatuForeColor(props.status ?? "")}
        textAlign={"center"}
        minWidth={45}
      >
        {props.status == FreeAgencyStatus.CAP_CASUALTY ? "CC" : props.status}
      </Typography>
    </Stack>
  );
};
