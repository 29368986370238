"use client";

import { Fade, Stack, styled } from "@mui/material";
import Head from "next/head";
import { NavBar } from "../../components/design/NavBar";
import { SumerScout } from "./SumerScout";
import { useParentHeightBoundary } from "../../components/util/useParentHeightBoundary";
import MyCollegeEvals from "../../components/home/college/MyCollegeEvals";
import { MyProEvals } from "../../components/home/pro/MyProEvals";
import { useGetUserQuery } from "../../graphql/generated/graphql";
import { MyEvalsSkeleton } from "./MyEvalsSkeleton";
import { PermissionEnum, hasPermission } from "../../utils/perms";
import AllEvals from "../../components/home/all/AllEvals";
import { Transactions } from "../../components/home/transactions/Transactions";
import MyPortalEvals from "../../components/home/portal/MyPortalEvals";

export default function Page() {
  const [scrollRef, scrollHeight] = useParentHeightBoundary();

  const { data, loading } = useGetUserQuery();
  const user = data?.user;

  const canReadAllEvals = hasPermission(
    user?.role?.permissions,
    PermissionEnum.PermissionEvalAllRead
  );
  const canReadTransactions = hasPermission(
    user?.role?.permissions, PermissionEnum.PermissionEvalAllRead
  );
  return (
    <>
      <NavBar />
      <Fade in>
        <ScoutHomeStyled>
          <Head>
            <title>Sumer Scout</title>
            <meta
              name="description"
              content="Page displaying the scouting home page"
            />
          </Head>
          <ScrollableContent ref={scrollRef} style={{ height: scrollHeight }}>
            <SumerScout />
            {!canReadAllEvals && (
              <>
                {loading && <MyEvalsSkeleton />}
                {!loading && <MyProEvals />}
                {!loading && <MyCollegeEvals />}
                {!loading && <MyPortalEvals />}
              </>
            )}
            {!loading && canReadAllEvals && <AllEvals />}
            {!loading && canReadTransactions && <Transactions />}
          </ScrollableContent>
        </ScoutHomeStyled>
      </Fade>
    </>
  );
}

const ScoutHomeStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0px 40px 0px 40px",
  height: "calc(100vh - 50px)",
  backgroundColor: theme.palette.grey[50],
}));

const ScrollableContent = styled(Stack)(() => ({
  overflowY: "scroll",
  "::-webkit-scrollbar": { width: 0, height: 0 },
  display: "flex",
  paddingLeft: "20px",
  paddingRight: "20px",
}));
