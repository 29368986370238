import { GridColDef } from "@mui/x-data-grid-premium";
import {
  getEvaluationGradeBackColor,
} from "../../../../utils/evals";
import { getPlayerHeight } from "../../../../utils/players";
import { PlayerEligibility } from "../../../design/PlayerEligibility";
import { NameCell, RankCell, SchoolCell } from "../../../common/tableCells";
import { Measurable } from "../../../common/measures/measures";
import { GradeCell } from "../../../common/tableCells/GradeCell";

export interface BigBoardEvalRow {
  rowId: string;
  position?: string;
  playerId: string;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  schoolId?: string | null | undefined;
  schoolCode?: string | null;
  rank?: number | null;
  grade?: number;
  crossCheckGrade?: number;
  crossCheckScout?: string;
  jersey?: string | null | undefined;
  positionSort: number | null | undefined;
  eligibility: string | null | undefined;
  redshirt: boolean;
  eligibilityYear: number | null | undefined;
  fallbackHeight?: number | null;
  fallbackWeight?: number | null;
  fallbackSpeed?: number | null;
  height: Measurable | undefined;
  weight: Measurable | undefined;
  speed: Measurable | undefined;
  hand: Measurable | undefined;
  arm: Measurable | undefined;
  wing: Measurable | undefined;
  wsd: Measurable | undefined;
  __reorder__: string;
}

export const getBigBoardEvalColumns = (): GridColDef<BigBoardEvalRow>[] => {
  const columns: GridColDef<BigBoardEvalRow>[] = [
    {
      field: "position",
      headerName: "Pos",
      width: 40,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.position,
      renderCell: (params) => {
        return params.row.position;
      },
      valueGetter: (params) => params.row.position,
    },
    {
      field: "positionSort",
      headerName: "Sort",
      width: 1,
      headerClassName: "column-header",
      type: "number",
      disableExport: true,
      groupingValueGetter: (params) => params.row.positionSort,
      renderCell: (params) => {
        return params.row.positionSort;
      },
      valueGetter: (params) => params.row.positionSort,
    },
    {
      field: "lastName",
      headerName: "Player",
      width: 220,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.lastName,
      renderCell: (params) => {
        if (!params.row.lastName) return null;
        return (
          <NameCell
            id={params.row.playerId}
            jersey={params.row.jersey ?? "0"}
            first={`${params.row.firstName[0]}.`}
            last={params.row.lastName}
            headshot={params.row.headshotUrl}
            anchor
            newTab
          />
        );
      },
      valueGetter: (params) =>
        params.row.lastName + ", " + params.row.firstName,
    },
    {
      field: "eligibility",
      headerName: "Elig",
      width: 40,
      headerClassName: "column-header",
      groupable: false,
      sortable: false,
      groupingValueGetter: (params) => params.row.redshirt,
      renderCell: (params) => {
        if (!params.row.eligibility) return null;
        return (
          <PlayerEligibility
            eligYear={params.row.eligibilityYear}
            elig={params.row.eligibility}
            redshirt={params.row.redshirt ?? false}
          />
        );
      },
      valueGetter: (params) =>
        params.row.redshirt
          ? "RS " + params.row.eligibility
          : params.row.eligibility,
    },
    {
      field: "school",
      headerName: "School",
      width: 90,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.schoolCode,
      renderCell: (params) => {
        if (!params.row.schoolCode) return null;
        return (
          <SchoolCell
            id={params.row.schoolId ?? ""}
            schoolCode={params.row.schoolCode}
            displayLogo={false}
          />
        );
      },
      valueGetter: (params) => params.row.schoolCode,
    },
    {
      field: "rank",
      headerName: "Rank",
      width: 60,
      disableExport: true,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.row.playerId) return null;
        return <RankCell rank={params.row.rank} />;
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      minWidth: 60,
      type: "number",
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.grade,
      renderCell: (params) => {
        return  <GradeCell grade={params.row.grade} />
      },
      valueGetter: (params) => params.row.grade?.toFixed(1),
    },
    {
      field: "crossCheckGrade",
      headerName: "X-Check",
      minWidth: 60,
      type: "number",
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.crossCheckGrade,
      renderCell: (params) => {
        return (
          <span
            style={{
              fontWeight: 700,
              backgroundColor: getEvaluationGradeBackColor(
                params.row.crossCheckGrade ?? 0
              ),
              minWidth: 60,
              textAlign: "center",
            }}
          >
            {params.row.crossCheckGrade?.toFixed(1)}{" "}
            <span style={{ fontWeight: 400, fontSize: 10 }}>
              {params.row.crossCheckScout &&
                params.row.crossCheckGrade &&
                params.row.crossCheckScout}
            </span>
          </span>
        );
      },
      valueGetter: (params) => params.row.crossCheckGrade?.toFixed(1),
    },
    {
      field: "height",
      headerName: "H",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          params.row.height?.value ?? getPlayerHeight(params.row.fallbackHeight)
        );
      },
      valueGetter: (params) =>
        params.row.height?.value ?? getPlayerHeight(params.row.fallbackHeight),
    },
    {
      field: "weight",
      headerName: "W",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.weight?.value ?? params.row.fallbackWeight;
      },
      valueGetter: (params) =>
        params.row.weight?.value ?? params.row.fallbackWeight,
    },
    {
      field: "speed",
      headerName: "S",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.speed?.value ?? params.row.fallbackSpeed;
      },
      valueGetter: (params) =>
        params.row.speed?.value ?? params.row.fallbackSpeed,
    },
    {
      field: "hand",
      headerName: "Hand",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.hand?.value;
      },
      valueGetter: (params) => params.row.hand?.value,
    },
    {
      field: "arm",
      headerName: "Arm",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.arm?.value;
      },
      valueGetter: (params) => params.row.arm?.value,
    },
    {
      field: "wing",
      headerName: "Wing",
      width: 60,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.wing?.value;
      },
      valueGetter: (params) => params.row.wing?.value,
    },
    {
      field: "wsd",
      headerName: "WSD",
      flex: 1,
      headerClassName: "column-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.wsd?.value;
      },
      valueGetter: (params) => params.row.wsd?.value,
    },
  ];

  return columns;
};

export default getBigBoardEvalColumns;
