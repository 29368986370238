import { formatDate } from "@sumer/shared/utils/dates";
import {
  EvalStatusEnum,
  EvalTypeEnum,
  ScoutTypeEnum,
  useGetAssignedCollegePlayersQuery,
  useGetLatestWarForPlayersQuery,
  useGetTraitGroupsQuery,
  useGetUserQuery,
} from "../../../graphql/generated/graphql";
import { getMissingTraitCount, getScoutAssignmentTypeDisplay } from "../../../utils/evals";
import { getPlayerAge, getPlayerFirstName } from "../../../utils/players";
import { getPlayerMeasures } from "../../common/measures/useGetPlayerMeasures";
import { useGetSchoolsForLookup } from "../../schools/useGetSchoolsForLookup";
import { CollegeEvalRow } from "./CollegeEvalColumns";
import { getDefaultSeason } from "../../../utils/season";
import _ from "lodash";
import { getDistinctAlerts, getDistinctFits } from "../homeUtils";

export const useGetCollegeEvaluationsForUser = () => {
  const { data, loading: userLoading } = useGetUserQuery();
  const user = data?.user;
  const defaultSeason = getDefaultSeason();

  // get all players tagged as TBG filtered by scout assignment
 const { data: playersToBeGraded, loading: playersToBeGradedLoading } = useGetAssignedCollegePlayersQuery({
  variables: {
    userId: user?.id ?? "no-id"
  },
  skip: user === undefined
});

  const { data: traitGroups, loading: traitGroupsLoading } = useGetTraitGroupsQuery();

  // get war
  const playerAssignments = playersToBeGraded?.scoutingAssignments.map((pg) => pg.player) ?? [];

  const playerIds = playerAssignments?.map((p) => p?.id) as string[];
  const playerWar = useGetLatestWarForPlayersQuery({
    variables: {
      playerIds: playerIds,
      league: "ncaa",
    },
    skip: playerIds === undefined
  });

  // get schools for lookup
  const schoolLookup = useGetSchoolsForLookup();

  const rows: CollegeEvalRow[] = [];

  playersToBeGraded?.scoutingAssignments?.forEach((a) => {

    // could be a position or club assignment or pro crosscheck (not currently implemented)
    if(!a.player || a.player.draftYear) return null;

    const player = a.player;

    const evalType = a.scoutType == ScoutTypeEnum.COLLEGE ? EvalTypeEnum.COLLEGE_SCOUT : EvalTypeEnum.COLLEGE_CROSS_CHECK;
    const latestSchool = schoolLookup.schools?.find(
      (s) => s.id == player?.latestSchoolId
    );
    const workoutMeasures = getPlayerMeasures(player?.workouts);

    // Get most recent eval for user of scout assignment type
    const scoutEvals = _.orderBy(a.player.evaluations.filter((e) => 
            e.user.id === user?.id 
         && e.season == defaultSeason
         && e.evalType.type == evalType), [s => s.lastUpdated], ["desc"]);
    const scoutEval = scoutEvals.find(s => s);

    // get prior season grade
    const priorSeasonGrade = player.evaluations.find((e) => e.season == defaultSeason - 1 
      && e.evalType.type == EvalTypeEnum.COLLEGE_SCOUT
      && e.status == EvalStatusEnum.FINAL)?.scoutGrade?.grade;

    const missingTraits = scoutEval ? getMissingTraitCount(
      scoutEval?.traitGrades,
      traitGroups?.traitGroups,
      scoutEval?.position.id
    ) : "";
    const aa = playerWar.data?.playerAdvancedAnalytics.find(
      (w) => w.marvalPlayerId == a.player?.id
    );

    rows.push({
      rowId: player?.id,
      type: getScoutAssignmentTypeDisplay(a.scoutType),
      jersey: player?.jersey,
      position: player?.sumerGeneralPosition?.shortName,
      fits: getDistinctFits(player, true) as string,
      alerts: getDistinctAlerts(player, true) as string,
      playerId: player?.id,
      firstName: getPlayerFirstName(player?.footballName ?? "", player?.firstName),
      lastName: player?.lastName ?? "",
      headshotUrl: player?.headshotUrl,
      schoolId: latestSchool?.id,
      schoolCode: latestSchool?.schoolCode,
      schoolName: latestSchool?.schoolName,
      grade: scoutEval?.scoutGrade?.grade,
      gameCount: scoutEval?.gameGrades.length.toString() ?? "",
      status: scoutEval?.status ?? "",
      hasBeenReviewed: scoutEval?.finalizedOn != null,
      eligibility: player.latestEligibility,
      redshirt: player.redshirt,
      eligibilityYear: player.latestEligibilityYear,
      fallbackHeight: player.height,
      fallbackWeight: player.weight,
      fallbackSpeed: player.speed,
      height: workoutMeasures.height,
      weight: workoutMeasures.weight,
      speed: workoutMeasures.speed,
      birthDate: player.latestBirthDate
        ? getPlayerAge(
            formatDate(new Date(player.latestBirthDate), "MM/DD/YYYY")
          )
        : undefined,
      lastUpdated: scoutEval?.lastUpdated ?? "",
      gradedTraitCount: missingTraits,
      pwaa: aa?.xWar,
      priorSeasonGrade: priorSeasonGrade
    });
  });

  return {
    rows,
    loading: playersToBeGradedLoading || traitGroupsLoading || userLoading,
    count: rows?.length,
  };
};

export default useGetCollegeEvaluationsForUser;
