import AllCollegeEvals from "./college/AllCollegeEvals";
import AllProEvals from "./pro/AllProEvals";
import BigBoardEvalsCollege from "./BigBoardEvalsCollege";
import BigBoardEvalsPro from "./BigBoardEvalsPro";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import FreeAgency from "../freeAgency/FreeAgency";

enum BoardType {
  COLLEGE = "College",
  PRO = "Pro",
  GRADE_RANK_COLLEGE = "Grade & Rank College",
  GRADE_RANK_PRO = "Grade & Rank Pro",
  FREE_AGENCY = "Free Agency",
  TAGS = "Tag Lists",
}

const AllEvals = () => {
  const [selectedBoard, setSelectedBoard] = useState<BoardType>(
    BoardType.COLLEGE
  );
  const handleBoardChange = (board: BoardType, newWindow: boolean) => {
    setSelectedBoard(board);
    if (newWindow) {
      window.open(`/tags`, "_blank");
    }
  };

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        marginTop={6}
        marginBottom={2}
      >
        <Typography variant="h4">Boards</Typography>
        <Stack direction="row">
          {Object.keys(BoardType).map((board) => (
            <Button
              variant={
                selectedBoard === BoardType[board as keyof typeof BoardType]
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                handleBoardChange(
                  BoardType[board as keyof typeof BoardType],
                  BoardType[board as keyof typeof BoardType] == BoardType.TAGS
                )
              }
              size="small"
              key={board}
              sx={{ margin: 1, }}
            >
              <Typography variant="h6">
                {BoardType[board as keyof typeof BoardType]}
              </Typography>
            </Button>
          ))}
        </Stack>
      </Stack>
      {selectedBoard === BoardType.COLLEGE && <AllCollegeEvals />}
      {selectedBoard === BoardType.PRO && <AllProEvals />}
      {selectedBoard === BoardType.GRADE_RANK_COLLEGE && (
        <BigBoardEvalsCollege />
      )}
      {selectedBoard === BoardType.GRADE_RANK_PRO && <BigBoardEvalsPro />}
      {selectedBoard === BoardType.FREE_AGENCY && <FreeAgency />}
    </>
  );
};

export default AllEvals;
