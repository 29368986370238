import { Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import {
  AlertsCell,
  MeasureCell,
  NameCell,
  SchoolCell,
} from "../../common/tableCells";
import {
  getEvaluationStatusText,
  StatusCell,
} from "../../common/tableCells/StatusCell";
import { PlayerEligibility } from "../../design/PlayerEligibility";
import { Measurable } from "../../common/measures/measures";
import { getPlayerHeight } from "../../../utils/players";
import { formatDate } from "@sumer/shared/utils/dates";
import { GradeCell } from "../../common/tableCells/GradeCell";

export interface PortalEvalRow {
  rowId: string;
  jersey?: string | null | undefined;
  position?: string;
  fits?: string | undefined;
  alerts?: string | undefined;
  playerId: string;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  schoolId?: string | null | undefined;
  schoolCode?: string | null;
  schoolName?: string;
  grade?: number;
  gameCount: string;
  status: string;
  hasBeenReviewed: boolean;
  eligibility: string | null | undefined;
  redshirt: boolean;
  eligibilityYear: number | null | undefined;
  fallbackHeight?: number | null | undefined;
  fallbackWeight?: number | null | undefined;
  fallbackSpeed?: number | null | undefined;
  height: Measurable | undefined;
  weight: Measurable | undefined;
  speed: Measurable | undefined;
  birthDate: number | undefined;
  lastUpdated: string;
  gradedTraitCount: string;
  pwaa: number | null | undefined;
}

export const getPortalEvalColumns = (): GridColDef<PortalEvalRow>[] => {
  const columns: GridColDef<PortalEvalRow>[] = [
    {
      field: "status",
      headerName: "Stage",
      width: 75,
      headerClassName: "column-header",
      groupingValueGetter: (params) =>
        getEvaluationStatusText(params.row.status),
      renderCell: (params) => {
        return (
          <StatusCell
            status={params.row.status}
            hasBeenReviewed={params.row.hasBeenReviewed}
          ></StatusCell>
        );
      },
      valueGetter: (params) => getEvaluationStatusText(params.row.status),
    },
    {
      field: "position",
      headerName: "Pos",
      width: 60,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.position,
      renderCell: (params) => {
        return params.row.position;
      },
      valueGetter: (params) => params.row.position,
    },
    {
      field: "fit",
      headerName: "FITs",
      width: 130,
      headerClassName: "column-header",
      groupingValueGetter: (params) => params.row.fits,
      renderCell: (params) => {
        return <FitsCell fits={params.row.fits}></FitsCell>;
      },
      valueGetter: (params) => params.row.fits,
    },
    {
      field: "lastName",
      headerName: "Player",
      width: 250,
      headerClassName: "column-header",
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row.lastName,
      renderCell: (params) => {
        if (!params.row.lastName) return null;
        return (
          <NameCell
            id={params.row.playerId}
            jersey={params.row.jersey ?? "0"}
            first={params.row.firstName}
            last={params.row.lastName}
            headshot={params.row.headshotUrl}
            newTab
          />
        );
      },
      valueGetter: (params) =>
        params.row.lastName + ", " + params.row.firstName,
    },
    {
      field: "school",
      headerName: "School",
      width: 90,
      headerClassName: "column-header",
      groupingValueGetter: (params) => params.row.schoolCode,
      renderCell: (params) => {
        if (!params.row.schoolCode || !params.row.schoolCode) return null;
        return (
          <SchoolCell
            schoolCode={params.row.schoolCode}
            id={params.row.schoolId ?? ""}
          />
        );
      },
      valueGetter: (params) => params.row.schoolCode,
    },
    {
      field: "eligibility",
      headerName: "Elig",
      width: 60,
      headerClassName: "column-header",
      groupable: false,
      sortable: false,
      groupingValueGetter: (params) => params.row.redshirt,
      renderCell: (params) => {
        if (!params.row.eligibility) return null;
        return (
          <PlayerEligibility
            eligYear={params.row.eligibilityYear}
            elig={params.row.eligibility}
            redshirt={params.row.redshirt ?? false}
          />
        );
      },
      valueGetter: (params) =>
        params.row.redshirt
          ? "RS " + params.row.eligibility
          : params.row.eligibility,
    },
    {
      field: "birthDate",
      headerName: "Age",
      width: 50,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "height",
      headerName: "H",
      width: 50,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.height?.value}
            verified={params.row.height?.verified}
            fallbackValue={getPlayerHeight(params.row.fallbackHeight)}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.height?.value ?? getPlayerHeight(params.row.fallbackHeight),
    },
    {
      field: "weight",
      headerName: "W",
      width: 50,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.weight?.value}
            verified={params.row.weight?.verified}
            fallbackValue={params.row.fallbackWeight}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.weight?.value ?? params.row.fallbackWeight,
    },
    {
      field: "speed",
      headerName: "S",
      width: 50,
      headerClassName: "column-header",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MeasureCell
            value={params.row.speed?.value}
            verified={params.row.speed?.verified}
            fallbackValue={params.row.fallbackSpeed}
            soft
          />
        );
      },
      valueGetter: (params) =>
        params.row.speed?.value ?? params.row.fallbackSpeed,
    },
    {
      field: "pwaa",
      headerName: "pWAA",
      type: "number",
      width: 65,
      headerClassName: "column-header",
      groupable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.pwaa?.toFixed(2),
    },
    {
      field: "grade",
      headerName: "Grade",
      minWidth: 60,
      type: "number",
      headerClassName: "column-header",
      groupingValueGetter: (params) => params.row.grade,
      renderCell: (params) => {
        return  <GradeCell grade={params.row.grade} />
      },
      valueGetter: (params) => params.row.grade,
    },
    {
      field: "alerts",
      headerName: "Alerts",
      width: 100,
      headerClassName: "column-header",
      sortable: false,
      groupable: false,
      groupingValueGetter: (params) => params.row.alerts,
      renderCell: (params) => {
        return <AlertsCell alerts={params.row.alerts}></AlertsCell>;
      },
      valueGetter: (params) => params.row.alerts,
    },
    {
      field: "gradedTraitCount",
      headerName: "Traits",
      width: 60,
      headerClassName: "column-header",
      groupable: false,
      sortable: false,
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return params.row.gradedTraitCount;
      },
      renderCell: (params) => {
        return params.row.gradedTraitCount;
      },
      valueGetter: (params) => params.row.gradedTraitCount,
    },
    {
      field: "gameCount",
      headerName: "Graded Games",
      minWidth: 100,
      headerClassName: "column-header",
      groupable: false,
      disableColumnMenu: true,
      groupingValueGetter: (params) => {
        return params.row.gameCount;
      },
      renderCell: (params) => {
        return params.row.gameCount;
      },
      valueGetter: (params) => params.row.gameCount,
    },
    {
      field: "lastUpdated",
      headerName: "L.Updated",
      flex: 1,
      minWidth: 95,
      headerClassName: "column-header",
      disableColumnMenu: true,
      type: "date",
      groupingValueGetter: (params) => params.row.lastUpdated,
      renderCell: (params) => {
        if (!params.row.lastUpdated || params.row.lastUpdated == "") return "";
        return formatDate(new Date(params.row.lastUpdated), "MM/DD/YYYY");
      },
      valueGetter: (params) => {
        return new Date(params.row.lastUpdated);
      },
    },
    {
      field: "eligibilityYear",
      headerName: "Draft Year",
      valueGetter: (params) => params.row.eligibilityYear,
    },
  ];

  return columns;
};

const FitsCell = (props: { fits?: string }) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Typography
      fontSize={12}
      fontWeight={500}
      color={colors.grey[600]}
      maxWidth={"100%"}
      sx={{ whiteSpace: "wrap" }}
    >
      {props.fits}
    </Typography>
  );
};

export default getPortalEvalColumns;
