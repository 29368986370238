import { toast } from "react-toastify";
import { formatDate } from "@sumer/shared/utils/dates";
import {
  EvalSummaryFragment,
  EvalTypeEnum,
  useGetEvalSummariesLazyQuery,
} from "../../../graphql/generated/graphql";
import { clubInformation } from "@sumer/shared/utils/clubStaticData";
import _ from "lodash";
import { useGetSchoolsForLookup } from "../../schools/useGetSchoolsForLookup";
import { getDefaultDraftYear } from "../../../utils/season";

function IsEvalSumerRecommendedClubFit(
  evaluation: EvalSummaryFragment | undefined,
  clubCode: string
) {
  if (evaluation) {
    const evalHasSumerRecommendedAlert = evaluation?.alerts.find(
      (ca) => ca.alert.abbreviation.indexOf("F") != -1
    );

    const evalHasClubFit = evaluation?.clubFits.find(
      (ca) => ca.club.clubCode == clubCode
    );

    return evalHasSumerRecommendedAlert && evalHasClubFit;
  }
  return false;
}

export const useExportEvals = (type: string) => {
  interface EvalExport {
    Club: string;
    Position: string;
    Fits: string;
    FirstName: string;
    LastName: string;
    Elig: string;
    School: string;
    Grade: string;
    XCheckGrade: string;
    Alerts: string;
    CrossCheckAlerts: string;
    ClubFits: string;
    CrossCheckClubFits: string;
    Scout: string;
    LastUpdated: string;
  }

  const [query, { loading }] = useGetEvalSummariesLazyQuery();

  const schoolLookup = useGetSchoolsForLookup();

  const clubs = clubInformation;

  const exportSumerRecommended = () => {
    const draftYear = getDefaultDraftYear(); 
    query({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const evals =
          type === "College"
            ? data.evals.filter(
                (e) =>
                  !e.player.draftYear &&
                  e.evalType.type == EvalTypeEnum.COLLEGE_SCOUT &&
                  e.season == draftYear
              )
            : data.evals.filter(
                (e) =>
                  e.player.draftYear &&
                  e.evalType.type == EvalTypeEnum.PRO_SCOUT
              );

        const crossChecks = data.evals?.filter(
          (e) =>
            !e.player.draftYear &&
            e.evalType.type == EvalTypeEnum.COLLEGE_CROSS_CHECK &&
            e.season == draftYear
        );

        const evalsToBeExported: EvalExport[] = [];
        let orderedEvals: EvalExport[] = [];

        if (evals) {
          clubs.forEach((c) => {
            evals.forEach((p) => {
              //get crosscheck if any
              const crossCheck = crossChecks?.find(
                (cc) => cc.player.id == p.player.id
              );
              // check if scout eval is sumer recommended club fit
              const evalIsSumerRecommendedClubFit =
                IsEvalSumerRecommendedClubFit(p, c.clubCode);

              // check if cross check is sumer recommended club fit
              const crossCheckIsSumerRecommendedClubFit =
                IsEvalSumerRecommendedClubFit(crossCheck, c.clubCode);

              if (
                evalIsSumerRecommendedClubFit ||
                crossCheckIsSumerRecommendedClubFit
              ) {
                const latestSchool = schoolLookup.schools?.find(
                  (s) => s.id == p.player.latestSchoolId
                );
                evalsToBeExported?.push({
                  Club: c.clubCode,
                  Position: p.position?.shortName,
                  Fits: p.fits?.map((f) => f.fit.name).join("-"),
                  FirstName: p.player.firstName,
                  LastName: p.player.lastName,
                  School: latestSchool?.schoolCode ?? "",
                  Elig: p.player.latestEligibility ?? "",
                  Grade: p.scoutGrade?.grade.toFixed(1).toString() ?? "",
                  XCheckGrade:
                    crossCheck?.scoutGrade?.grade.toFixed(1).toString() ?? "",
                  Alerts: p.alerts.map((a) => a.alert.abbreviation).join("-"),
                  CrossCheckAlerts:
                    crossCheck?.alerts
                      .map((ca) => ca.alert.abbreviation)
                      .join("-") ?? "",
                  ClubFits: p.clubFits.map((cf) => cf.club.clubCode).join("-"),
                  CrossCheckClubFits:
                    crossCheck?.clubFits
                      .map((ccf) => ccf.club.clubCode)
                      .join("-") ?? "",
                  Scout: `${p.user.firstName?.substring(0, 1)}. ${
                    p.user.lastName
                  }`,
                  LastUpdated: formatDate(
                    new Date(p.lastUpdated),
                    "MM/DD/YYYY"
                  ),
                });
              }
            });
          });
          // order and add header
          orderedEvals = _.orderBy(
            evalsToBeExported,
            ["Club", "Grade"],
            ["asc", "desc"]
          );

          //add header
          if (type == "College") {
            orderedEvals = _.concat(
              [
                {
                  Club: "Club",
                  Position: "Position",
                  Fits: "FITs",
                  FirstName: "First Name",
                  LastName: "Last Name",
                  School: "School",
                  Elig: "Elig",
                  Grade: "Grade",
                  XCheckGrade: "X-Check",
                  Alerts: "Alerts",
                  CrossCheckAlerts: "X-Alerts",
                  ClubFits: "ClubFits",
                  CrossCheckClubFits: "X-ClubFits",
                  Scout: "Scout",
                  LastUpdated: "LastUpdated",
                },
              ],
              orderedEvals
            );
          }
        }

        const convertToCsv = (data: EvalExport[]) => {
          let csvContent = "data:text/csv;charset=utf-8,";

          data.forEach((pe) => {
            csvContent +=
              pe.Club +
              "," +
              pe.Position +
              "," +
              pe.Fits +
              "," +
              pe.FirstName +
              "," +
              pe.LastName +
              "," +
              pe.Elig +
              "," +
              pe.School +
              "," +
              pe.Grade +
              "," +
              pe.XCheckGrade +
              "," +
              pe.Alerts +
              "," +
              pe.CrossCheckAlerts +
              "," +
              pe.ClubFits +
              "," +
              pe.CrossCheckClubFits +
              "," +
              pe.Scout +
              "," +
              pe.LastUpdated +
              "\n";
          });
          const encodedUri = encodeURI(csvContent);

          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "Sumer Recommended.csv");
          document.body.appendChild(link);

          link.click();
          toast.success("Sumer Recommended downloaded!", {
            position: "top-center",
          });
        };

        convertToCsv(orderedEvals);
      },
    });
  };

  return { exportSumerRecommended, loading };
};

export default useExportEvals;
